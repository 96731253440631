import React, { useRef } from 'react'
import { defer } from 'lodash'

import { withI18nContext, useTranslation } from '@mc/i18n'
import { Slider } from '@mc/design-system'

import { VideoMute, useVideo } from '..'
import i18nInstance from '../../i18n/i18nInstance'
import { useVideoSetting } from '../userSettings'
import { VideoControl } from './VideoControl'

/**
 * Displays an interactive volume control
 *
 * Backed by localStorage
 */
export const VideoVolume = withI18nContext(i18nInstance)(() => {
  const { volume, setVolume } = useVideo()
  const { t } = useTranslation('@mc/video')

  const [savedVolume, setSavedVolume] = useVideoSetting('video_volume')
  const [, setSavedMuted] = useVideoSetting('video_muted')
  const initializedRef = useRef<boolean>(false)

  if (!initializedRef.current) {
    if (typeof savedVolume !== 'undefined') {
      defer(setVolume, savedVolume)
    }
    initializedRef.current = true
  }

  const handleChange = (newValue: number) => {
    setSavedVolume(newValue)
    if (newValue > 0) {
      setSavedMuted(false)
    }
    setVolume(newValue)
  }

  return (
    <VideoControl className='mc-video__volume'>
      <VideoMute />

      <div className='mc-video__volume-slider'>
        <Slider
          label={t('video.volume.title')}
          value={volume}
          color='light'
          onChange={handleChange}
        />
      </div>
    </VideoControl>
  )
})
