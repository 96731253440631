/* istanbul ignore file */
import * as React from 'react'
import type { SVGProps } from 'react'
import PropTypes from 'prop-types'

interface Props extends SVGProps<SVGSVGElement> {
  srText?: string
  size?: 'sm' | 'md' | 'lg' | 'scale-8' | 'scale-10' | number
  alt?: string
}
function IconCcDefault({
  srText,
  size = 'md',
  className = '',
  ...props
}: Props) {
  const newClassName = `mc-icon mc-icon--${size} ${className}`
  return (
    <>
      {!!srText && <span className='mc-sr-only'>{srText}</span>}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={25}
        fill='none'
        viewBox='0 0 24 24'
        aria-hidden='true'
        role='img'
        className={newClassName}
        {...props}
      >
        <path
          fill='currentColor'
          d='M16.88 13.498c-.128.637-.583.976-1.279.976-.911 0-1.432-.695-1.432-1.98 0-1.27.515-1.952 1.432-1.952.725 0 1.163.403 1.28 1.016h1.59c-.141-1.467-1.163-2.413-2.87-2.413-2.015 0-3.15 1.21-3.15 3.348 0 2.163 1.14 3.377 3.15 3.377 1.678 0 2.729-.928 2.87-2.372zM9.96 13.498c-.13.637-.585.976-1.28.976-.912 0-1.432-.695-1.432-1.98 0-1.27.514-1.952 1.432-1.952.724 0 1.163.403 1.28 1.016h1.589c-.14-1.467-1.163-2.413-2.87-2.413-2.016 0-3.15 1.21-3.15 3.348 0 2.163 1.14 3.377 3.15 3.377 1.677 0 2.73-.928 2.87-2.372z'
        />
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M5.99 3.531h12.02A3.99 3.99 0 0 1 22 7.521v9.972a3.99 3.99 0 0 1-3.99 3.99H5.99A3.99 3.99 0 0 1 2 17.493V7.52a3.99 3.99 0 0 1 3.99-3.989m0 .998A2.99 2.99 0 0 0 2.996 7.52v9.973a2.99 2.99 0 0 0 2.992 2.992h12.022a2.99 2.99 0 0 0 2.992-2.992V7.52A2.99 2.99 0 0 0 18.01 4.53z'
          clipRule='evenodd'
        />
      </svg>
    </>
  )
}
IconCcDefault.propTypes = {
  srText: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
} as PropTypes.ValidationMap<Props>
export default IconCcDefault
