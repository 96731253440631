import { useEffect, useRef } from 'react'
import { isEqual } from 'lodash'

/**
 * Like `useEffect`, but only calls the callback if the dependencies are different on a *deep* compare
 *
 * @param callback
 * @param dependencies
 */
export const useCallOnEachValue = <T>(
  callback: (prev: T | null, curr: T) => void,
  dependencies: T,
) => {
  const previousDependencies = useRef<T | null>(null)

  useEffect(() => {
    if (!isEqual(previousDependencies.current, dependencies)) {
      callback(previousDependencies.current, dependencies)
      previousDependencies.current = dependencies
    }
  }, [callback, dependencies])
}
