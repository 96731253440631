import React from 'react'

import { withI18nContext, useTranslation } from '@mc/i18n'
import {
  Button,
  IconPauseDefault,
  IconPlayDefault,
  IconReplayDefault,
} from '@mc/design-system'

import { useVideo } from '..'
import i18nInstance from '../../i18n/i18nInstance'
import { VideoControl } from './VideoControl'

/**
 * Displays an interactive play/pause/replay button
 */
export const VideoPlayback = withI18nContext(i18nInstance)((
  props: React.ComponentProps<typeof Button>,
) => {
  const { autoPlay, state, play, pause, restart } = useVideo()
  const { t } = useTranslation('@mc/video')

  let action
  let handler: (() => void) | (() => Promise<void>)
  let IconComponent

  switch (state) {
    case 'playing':
      action = t('video.playback.pause')
      handler = pause
      IconComponent = IconPauseDefault
      break

    case 'ended':
      action = t('video.playback.replay')
      handler = restart
      IconComponent = IconReplayDefault
      break

    case 'idle':
      if (autoPlay) {
        action = t('video.playback.pause')
        handler = pause
        IconComponent = IconPauseDefault
        break
      }
    // fall through

    default:
      action = t('video.playback.play')
      handler = play
      IconComponent = IconPlayDefault
      break
  }

  return (
    <VideoControl>
      <Button
        onClick={() => handler()}
        kind='link'
        size='small'
        symmetrical
        {...props}
      >
        <IconComponent
          srText={action}
          aria-live='polite'
          className='mc-icon mc-icon--scale-5'
        />
      </Button>
    </VideoControl>
  )
})
