import React from 'react'

import type {
  QualityLevelOption,
  AudioTrack,
  ReadyState,
  Source,
  State,
  Track,
} from '.'

export type AutoQualityOption = {
  name: 'Auto'
  value: -1
}

export interface VideoContextValue {
  /**
   * Whether this video player is the active one
   *
   * In the case of multiple video players in the same component tree,
   * the most recently played one will be "active", and the rest
   * will automatically pause and be active=false.
   */
  active: boolean
  /**
   * The value of the `autoPlay` prop
   */
  autoPlay: boolean | undefined
  /**
   * Whether the most recent "play" event was due to an autoplay
   */
  autoPlayed: boolean
  /**
   * The number of currently buffered time ranges
   */
  buffer: number
  /**
   * The active text track cues, or null if none
   */
  textTrackCues: VTTCue[] | null
  /**
   * The duration of the video
   */
  duration: number
  /**
   * The current error, if any
   */
  error: MediaError | null
  /**
   * Whether the video is in full-screen mode
   * *Note:* Mobile Safari does not support any fullscreen customization,
   * so this will always be false on Safari iOS
   */
  fullscreen: boolean
  /**
   * The calculated offset height of the video player bounding rectangle
   */
  height: number
  /**
   * Whether the user is currently interacting with the video,
   * eg. via mouse movement. This is preferred to hover states.
   *
   * The container also has a `.mc-video--interacting` CSS class
   * when this is true.
   */
  interacting: boolean
  /**
   * Time since source load, in milliseconds
   */
  initTime: number
  /**
   * Whether the video's audio is muted
   */
  muted: boolean
  /**
   * URL for the poster image of the video
   */
  poster: string
  /**
   * The available quality level options
   */
  qualityLevelOptions: readonly [AutoQualityOption, ...QualityLevelOption[]]
  /**
   * The currently selected qualityLevelOption (including Auto)
   */
  qualityUserSelection: AutoQualityOption | QualityLevelOption
  /**
   * The actual video quality, regardless of user selection
   * Will never be Auto
   */
  qualityActual: QualityLevelOption | null
  /**
   * False unless the user is actively seeking a new time in the video
   */
  seeking: boolean
  /**
   * Last seek start position. May be nonzero even if `seeking` is `false`.
   */
  lastSeekStartPosition: number
  /**
   * a `Source` object, containing `src` and `type` properties
   * Will load when provided
   */
  source: Source | null
  /**
   * For tracking which variety of player eg. hls.js, native hls, non-hls
   */
  decoder: 'hls.js' | 'native-hls' | 'non-hls'
  /**
   * The current playback speed of the video
   */
  speed: number
  /**
   * The current state of the video
   */
  state: State
  readyState: ReadyState
  /**
   * The current playback time of the video, in seconds
   */
  time: number
  /**
   * Whether the video is stalled (after a `waiting` event)
   */
  waiting: boolean
  /**
   * The available audio tracks
   */
  audioTracks: readonly AudioTrack[]
  /**
   * The index in "audioTracks" of the currently selected audio track
   */
  audioTrack: number
  /**
   * The available text tracks
   * Each track corresponds to a language; the individual
   * lines of text are called "cues", which populate
   * "textTrackCues" during playback
   */
  tracks: readonly Track[]
  /**
   * The index in "tracks" of the currently selected text track
   * -1 denotes no track (and thus no subtitles)
   */
  track: number
  /**
   * The volume of the video's audio playback
   */
  volume: number
  /**
   * The calculated offset width of the video player bounding rectangle
   */
  width: number

  /**
   * Pause the video
   */
  pause: () => void
  /**
   * Play the video.
   * Note this returns a Promise, which should be awaited before changing playback state.
   * @returns {Promise}
   */
  play: (p?: { autoPlay?: boolean }) => Promise<void>
  /**
   * Restart the video from the beginning
   */
  restart: () => Promise<void>

  handleInteraction: () => void
  contentPlayingStartTime: number

  /**
   * Sets the currently selected audio track from within "audioTracks"
   * @param track The index of the audio track to select
   */
  setAudioTrack: (track: number) => void
  /**
   * Mute the video
   * @param muted
   */
  setMuted: (muted: boolean) => void
  /**
   * Sets the current video quality index in "qualityLevelOptions"
   *
   * @param index The index of the quality option to select
   */
  setQualityLevelValue: (value: number) => void
  /**
   * Set the playback speed
   * @param speed The playback speed
   */
  setSpeed: (speed: number) => void
  /**
   * Sets the currentTime of the video
   * @param time The time to set, in seconds
   */
  seekTo: (time: number) => Promise<void>
  /**
   * Seek to the given time. The video will be in a "seeking" state until "seekTo" is called.
   * @param time The time to seek to
   */
  seekingTo: (time: number) => void
  /**
   * Sets the index of the currently selected text track from within "tracks"
   * @param track The index of the text track to select
   */
  setTrack: (track: number) => void
  /**
   * Sets the volume
   *
   * @param volume The volume to set to
   */
  setVolume: (volume: number) => void

  /**
   * Request fullscreen mode
   */
  toggleFullscreen: () => void
  /**
   * Toggle play
   */
  togglePlay: () => Promise<void>
  /**
   * Toggle mute
   */
  toggleMute: () => void
}

export const VideoContext = React.createContext<VideoContextValue | null>(null)

/**
 * Get the context state of the current video
 * Useful for custom controls or video behaviors
 *
 * @returns The current video context
 */
export const useVideo = () => {
  const ctx = React.useContext(VideoContext)
  if (!ctx) {
    throw new Error('useVideo must be used within a VideoProvider')
  }

  return ctx
}
