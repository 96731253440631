import React, { createContext, useContext } from 'react'
import { TRACK_PROPS, TrackContentType } from '@mc/track-event'

export * from './createTrackingContext'
export * from './segmentTrackingContext'

type PageType =
  | 'Content Watch post-paywall'
  | 'Sessions post-paywall'
  | 'Homepage post-paywall'
  | 'Class Details Page'
  | 'onboarding'
  | 'next-articles'
  | 'next-categories'
  | 'next-home'
  | 'next-sessions'
  | 'next-classes'
  | 'next-episodes'
  | 'next-gift'
  | 'next-howto'
  | 'frontlineworker'
  | 'Subcategory'
  | 'Outcome'
  | `Test: ${string}`
  | 'Video Embed'

export interface TrackingParams {
  [TRACK_PROPS.PLAYER_SESSION_ID]?: string
  [TRACK_PROPS.MEDIA_SESSION_ID]?: string
  [TRACK_PROPS.VISIT_SESSION_ID]?: string

  [TRACK_PROPS.CONTENT_ID]?: string | number
  [TRACK_PROPS.CONTENT_UUID]?: string
  [TRACK_PROPS.PARENT_UUID]?: string
  [TRACK_PROPS.CONTENT_TYPE]?: TrackContentType
  [TRACK_PROPS.CONTENT_TITLE]?: string
  [TRACK_PROPS.COURSE_ID]?: string | number

  [TRACK_PROPS.PLAYER_NAME]?: string
  [TRACK_PROPS.PAGE_TYPE]?: PageType
}

export const TrackingContext = createContext<TrackingParams>({})

export const useTrackingContext = () => useContext(TrackingContext)

export const TrackingParamsProvider = ({
  children,
  value,
}: {
  children: React.ReactNode
  value: TrackingParams
}) => {
  const parentValue = useTrackingContext()

  return (
    <TrackingContext.Provider value={{ ...parentValue, ...value }}>
      {children}
    </TrackingContext.Provider>
  )
}
