import { Reducer } from 'react'
import mux from 'mux-embed'
import { Level, MediaPlaylist } from 'hls.js'
import { cloneDeep, pick } from 'lodash'
import { AutoQualityOption, VideoContextValue } from './VideoContext'
import { dedupeAndMapLevels } from './dedupeLevels'
import { QualityLevelOption } from '.'

export type Actions =
  | { type: 'init'; payload: { muted?: boolean } }
  | { type: 'ready' }
  | { type: 'pause' }
  | { type: 'play' }
  | { type: 'playing' }
  | { type: 'ended' }
  | { type: 'waiting' }
  | { type: 'error'; payload: MediaError }
  | { type: 'autoPlayed'; payload: boolean }
  | { type: 'interacted' }
  | { type: 'nonInteracted' }
  | { type: 'activated'; payload: boolean }
  | { type: 'bufferUpdated'; payload: number }
  | { type: 'durationChanged'; payload: number }
  | { type: 'audioTrackChanged'; payload: number }
  | { type: 'sizeUpdated'; payload: { width: number; height: number } }
  | { type: 'speedChanged'; payload: number }
  | { type: 'timeUpdated'; payload: number }
  | { type: 'trackChanged'; payload: number }
  | { type: 'volumeChanged'; payload: { volume: number; muted: boolean } }
  | { type: 'seeking'; payload: number }
  | { type: 'seeked'; payload: number }
  | { type: 'fullscreenToggled'; payload: boolean }
  | { type: 'audioTracksUpdated'; payload: readonly MediaPlaylist[] }
  | { type: 'textTrackCuesUpdated'; payload: TextTrackCueList | null }
  | {
      type: 'qualityLevelSelected'
      payload: { levels: readonly Level[]; userValue: number }
    }
  | {
      type: 'qualityLevelsUpdated'
      payload: {
        levels: readonly Level[]
        indexActual: number
      }
    }

export const AUTO_QUALITY_OPTION = {
  name: 'Auto',
  value: -1,
} as const

type VideoState = Pick<
  VideoContextValue,
  | 'active'
  | 'autoPlayed'
  | 'buffer'
  | 'textTrackCues'
  | 'duration'
  | 'error'
  | 'fullscreen'
  | 'height'
  | 'interacting'
  | 'initTime'
  | 'muted'
  | 'qualityActual'
  | 'qualityLevelOptions'
  | 'qualityUserSelection'
  | 'seeking'
  | 'speed'
  | 'state'
  | 'readyState'
  | 'time'
  | 'waiting'
  | 'audioTracks'
  | 'audioTrack'
  | 'track'
  | 'volume'
  | 'width'
  | 'contentPlayingStartTime'
  | 'lastSeekStartPosition'
>

export const initialState: VideoState = {
  active: false,
  autoPlayed: false,
  buffer: 0,
  textTrackCues: null,
  duration: 0,
  error: null,
  fullscreen: false,
  muted: false,
  height: 0,
  interacting: false,
  initTime: 0,
  qualityActual: null,
  qualityLevelOptions: [AUTO_QUALITY_OPTION],
  qualityUserSelection: AUTO_QUALITY_OPTION,
  seeking: false,
  speed: 1,
  state: 'idle',
  readyState: 'loading',
  time: 0,
  waiting: false,
  audioTracks: [],
  audioTrack: -1,
  track: -1,
  volume: 1,
  width: 0,
  contentPlayingStartTime: 0,
  lastSeekStartPosition: 0,
}

/**
 * This reducer reflects the video state downstream from changes in the underlying
 * <video> DOM element and HLS instance. It is meant to be dispatched from within
 * event handlers of that element, in order to update the UI. It should not be used
 * to directly update the <video> element.
 */
// eslint-disable-next-line consistent-return
export const reducer: Reducer<VideoState, Actions> = (prevState, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case 'ready': {
      return { ...prevState, readyState: 'ready' }
    }
    case 'pause': {
      return { ...prevState, state: 'paused' }
    }
    case 'play': {
      const contentPlayingStartTime =
        prevState.state === 'paused' || prevState.error
          ? prevState.time
          : prevState.contentPlayingStartTime
      return {
        ...prevState,
        error: null,
        state: 'playing',
        contentPlayingStartTime,
      }
    }
    case 'ended': {
      return { ...prevState, state: 'ended', textTrackCues: null }
    }
    case 'waiting': {
      if (prevState.waiting) {
        return prevState
      }
      return { ...prevState, waiting: true }
    }
    case 'playing': {
      if (!prevState.waiting) {
        return prevState
      }
      return { ...prevState, waiting: false }
    }
    case 'error': {
      return { ...prevState, error: action.payload }
    }
    case 'autoPlayed': {
      if (action.payload === prevState.autoPlayed) return prevState
      return { ...prevState, autoPlayed: action.payload }
    }
    case 'interacted': {
      if (prevState.interacting) {
        return prevState
      }
      return { ...prevState, interacting: true }
    }
    case 'nonInteracted': {
      if (!prevState.interacting) {
        return prevState
      }
      return { ...prevState, interacting: false }
    }
    case 'timeUpdated': {
      if (Math.floor(action.payload) === Math.floor(prevState.time)) {
        return prevState
      }
      return { ...prevState, time: Math.floor(action.payload) }
    }
    case 'activated': {
      return { ...prevState, active: action.payload }
    }
    case 'audioTrackChanged': {
      return {
        ...prevState,
        audioTrack: prevState.audioTracks.findIndex(
          (at) => at.id === action.payload,
        ),
      }
    }
    case 'bufferUpdated': {
      return { ...prevState, buffer: action.payload }
    }
    case 'durationChanged': {
      // bug fix for: https://masterclass-dev.atlassian.net/browse/PEN-3149
      if (Number.isNaN(action.payload)) {
        return prevState
      }

      return { ...prevState, duration: Math.floor(action.payload) }
    }
    case 'fullscreenToggled': {
      return { ...prevState, fullscreen: action.payload }
    }
    case 'volumeChanged': {
      const { volume, muted } = action.payload
      return {
        ...prevState,
        muted: volume === 0 || muted,
        volume: muted ? 0 : volume,
      }
    }
    case 'seeking': {
      const currentTime = Math.floor(action.payload)
      if (prevState.seeking) {
        return { ...prevState, time: currentTime }
      }

      return {
        ...prevState,
        seeking: true,
        time: currentTime,
        lastSeekStartPosition: prevState.time,
      }
    }
    case 'seeked': {
      const currentTime = Math.floor(action.payload)
      const isAtEnd = currentTime >= prevState.duration
      const newState =
        !isAtEnd && prevState.state === 'ended' ? 'paused' : prevState.state
      return {
        ...prevState,
        seeking: false,
        state: newState,
        time: currentTime,
        contentPlayingStartTime: currentTime,
      }
    }
    case 'speedChanged': {
      return { ...prevState, speed: action.payload }
    }
    case 'trackChanged': {
      return {
        ...prevState,
        track: action.payload,
        textTrackCues: null,
      }
    }
    case 'textTrackCuesUpdated': {
      return {
        ...prevState,
        textTrackCues: action.payload
          ? (Array.from(action.payload) as VTTCue[])
          : null,
      }
    }
    case 'audioTracksUpdated': {
      return {
        ...prevState,
        audioTracks: action.payload.map((hlsTrack) =>
          pick(hlsTrack, ['id', 'url', 'name']),
        ),
      }
    }
    case 'qualityLevelSelected': {
      const { levels, userValue } = action.payload
      const availableLevels = dedupeAndMapLevels(levels)

      const qualityLevelOptions = [
        AUTO_QUALITY_OPTION,
        ...availableLevels,
      ] as const

      const qualityUserSelection = qualityLevelOptions.find(
        ({ value }) => value === userValue,
      ) as QualityLevelOption | AutoQualityOption

      return {
        ...prevState,
        qualityLevelOptions,
        qualityUserSelection,
      }
    }
    case 'qualityLevelsUpdated': {
      const { levels, indexActual } = action.payload
      const availableLevels = dedupeAndMapLevels(levels)

      const qualityActual =
        availableLevels.find(({ value }) => value === indexActual) ?? null

      const qualityLevelOptions = [
        AUTO_QUALITY_OPTION,
        ...availableLevels,
      ] as const

      if (
        indexActual === prevState.qualityActual?.value &&
        qualityLevelOptions.length === prevState.qualityLevelOptions.length
      ) {
        return prevState
      }

      return {
        ...prevState,
        qualityLevelOptions,
        qualityActual,
      }
    }
    case 'sizeUpdated': {
      if (
        action.payload.height === prevState.height &&
        action.payload.width === prevState.width
      )
        return prevState

      return {
        ...prevState,
        ...action.payload,
      }
    }
    case 'init': {
      return {
        ...cloneDeep(initialState),
        initTime: mux.utils.now(),
        muted: action.payload.muted ?? false,
        volume: action.payload.muted ? 0 : 1,
      }
    }
  }
}
