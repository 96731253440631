import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { Trans, useTranslation } from '@mc/i18n'
import { Button, Sticky } from '@mc/design-system'
import { TrackingContainer } from '@mc/visibility'

import { useTiers } from '../TierProvider/TierProvider'
import { useCtaToPurchaseUrl } from '../../utils/cta'
import { paths } from '../../utils/paths'
import { useAuth0User } from '../../hooks/useAuth0User'
import { ROW_TRACKING } from '../../features/LoggedOutLandingPage/utils'
import { usePersonaEvents } from '../../events'
import { PageSources } from '../../types/tracking'
import {
  getPageSource,
  type ExtendedSources,
} from '../../hooks/tracking/events'
import { useNonCommerceGoldStandardEvents } from '../../hooks/tracking/useNonCommerceGoldStandardEvents'

import styles from './StickyTierFooter.module.scss'

interface StickyTierFooterContentProps {
  rowAnalytics?: {
    rowName: string
    rowPositionIndex: number
  }
  className?: string
  source: PageSources
}

interface StickyTierFooterProps extends StickyTierFooterContentProps {
  mobileShowAtPosition: number
  desktopShowAtPosition: number
}

export const StickyTierFooter = ({
  mobileShowAtPosition,
  desktopShowAtPosition,
  ...props
}: StickyTierFooterProps) => (
  <>
    {/* Mobile */}
    <Sticky
      showAtPosition={mobileShowAtPosition}
      className={`d-block d-sm-none ${styles.stickyContainer}`}
    >
      <StickyTierFooterContent {...props} />
    </Sticky>
    {/* Desktop */}
    <Sticky
      showAtPosition={desktopShowAtPosition}
      className={`d-none d-sm-block ${styles.stickyContainer}`}
    >
      <StickyTierFooterContent {...props} />
    </Sticky>
  </>
)

const StickyTierFooterContent = ({
  rowAnalytics = ROW_TRACKING.stickyFooter,
  source,
  className = '',
}: StickyTierFooterContentProps) => {
  const { t } = useTranslation('@mc/persona')
  const getCtaToPurchaseUrl = useCtaToPurchaseUrl()
  const { user, isLoading } = useAuth0User()
  const { trackPersonaRowViewed, trackPersonaClick } = usePersonaEvents()
  const isLoggedIn = !!user && !isLoading
  const { lowestTier } = useTiers()
  const { slug: instructorSlug } = useRouter().query
  const { trackPurchaseClick } = useNonCommerceGoldStandardEvents(source)

  const handleRowViewed = () => {
    trackPersonaRowViewed(rowAnalytics)
  }

  const handleCtaClick = () => {
    const sourceWithUser = isLoggedIn
      ? source
      : (`${source}NoUser` as ExtendedSources)
    const pageSource = getPageSource(
      sourceWithUser,
      instructorSlug as string | undefined,
    )
    trackPersonaClick({
      location: 'sticky footer',
      cta: 'sign up',
      additionalAnalytics: {
        pageSource,
      },
    })
    trackPurchaseClick({
      location: 'sticky footer',
      cta: 'sign up',
      pageSource,
    })
  }

  return (
    <TrackingContainer onVisible={handleRowViewed}>
      <div
        className={`d-flex align-items-center justify-content-between ${className}`}
      >
        <p>
          <Trans
            ns='@mc/persona'
            i18nKey={
              lowestTier
                ? 'stickyTierFooter.text'
                : 'stickyTierFooter.textFallback'
            }
            values={{
              price: lowestTier?.internalData.prices.fullMonthlyWithCurrency,
            }}
          >
            <span className='mc-text--bold' />
          </Trans>
        </p>
        <Button
          className={styles.ctaButton}
          as={Link}
          onClick={handleCtaClick}
          href={getCtaToPurchaseUrl(
            isLoggedIn
              ? paths.personaCheckoutMembership
              : paths.personaCheckoutSignUp,
            source,
          )}
        >
          {t('stickyTierFooter.cta')}
        </Button>
      </div>
    </TrackingContainer>
  )
}
