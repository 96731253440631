import React from 'react'

import {
  VideoAudioTracks,
  VideoClosedCaptions,
  VideoContainer,
  VideoFullscreen,
  VideoLoader,
  VideoPlayback,
  VideoQualityLevel,
  VideoScrubber,
  VideoSpeed,
  VideoSpacer,
  VideoTime,
  VideoVolume,
} from '..'
import { Segment, VideoSegmentProps } from './VideoSegments'

type VideoControlsProps<TSegment extends Segment> = Partial<
  VideoSegmentProps<TSegment>
> & {
  /**
   * Content to render as a popup over the mouse cursor position on the progress bar.
   * Receives the current time as a render prop.
   */
  popup?: (time: number) => React.ReactNode
  /**
   * Additional controls to render to the left of the spacer
   */
  controlsLeft?: React.ReactNode
  /**
   * Additional controls to render to the right of the spacer
   */
  controlsRight?: React.ReactNode
  /**
   * Custom loader component to render when the video is loading
   */
  customLoader?: React.ReactNode
}

/**
 * A general-purpose set of controls for the video player.
 * Includes a loader, progress bar, play/pause, volume, time,
 * speed, cc, quality, audio tracks, and fullscreen controls
 */
export const VideoControls = <TSegment extends Segment>({
  segments,
  popup,
  controlsLeft,
  controlsRight,
  customLoader,
  onSegmentEnter,
  onSegmentExit,
}: VideoControlsProps<TSegment>) => (
  <>
    <VideoLoader customLoader={customLoader} />
    <VideoContainer>
      <VideoScrubber
        segments={segments}
        popup={popup}
        onSegmentEnter={onSegmentEnter}
        onSegmentExit={onSegmentExit}
      />
      <VideoPlayback />
      <VideoVolume />
      <VideoTime />
      {controlsLeft}
      <VideoSpacer />
      {controlsRight}
      <VideoSpeed />
      <VideoClosedCaptions />
      <VideoQualityLevel />
      <VideoAudioTracks />
      <VideoFullscreen />
    </VideoContainer>
  </>
)
