import React, { useRef } from 'react'
import { defer } from 'lodash'

import { withI18nContext, useTranslation } from '@mc/i18n'

import {
  Button,
  IconVolumeOffDefault,
  IconVolumeOnDefault,
} from '@mc/design-system'

import { useVideo } from '..'
import i18nInstance from '../../i18n/i18nInstance'
import { useVideoSetting } from '../userSettings'

type VideoMuteProps = React.ComponentProps<typeof Button>

/**
 * Displays an interactive mute/unmute button
 *
 * Backed by localStorage
 */
export const VideoMute = withI18nContext(i18nInstance)((
  props: VideoMuteProps,
) => {
  const { muted, setMuted, toggleMute } = useVideo()
  const { t } = useTranslation('@mc/video')

  const [savedMuted, setSavedMuted] = useVideoSetting('video_muted')
  const initializedRef = useRef<boolean>(false)

  if (!initializedRef.current) {
    if (typeof savedMuted !== 'undefined') {
      defer(setMuted, savedMuted)
    }
    initializedRef.current = true
  }

  let action
  let IconComponent

  if (muted) {
    action = t('video.mute.disable')
    IconComponent = IconVolumeOffDefault
  } else {
    action = t('video.mute.enable')
    IconComponent = IconVolumeOnDefault
  }

  const handleClick = () => {
    setSavedMuted(!muted)
    toggleMute()
  }

  return (
    <Button
      onClick={handleClick}
      kind='link'
      size='small'
      symmetrical
      {...props}
    >
      <IconComponent
        srText={action}
        aria-live='polite'
        className='mc-icon mc-icon--4'
      />
    </Button>
  )
})
