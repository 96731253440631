/* istanbul ignore file */
import * as React from 'react'
import type { SVGProps } from 'react'
import PropTypes from 'prop-types'

interface Props extends SVGProps<SVGSVGElement> {
  srText?: string
  size?: 'sm' | 'md' | 'lg' | 'scale-8' | 'scale-10' | number
  alt?: string
}
function IconReplayDefault({
  srText,
  size = 'md',
  className = '',
  ...props
}: Props) {
  const newClassName = `mc-icon mc-icon--${size} ${className}`
  return (
    <>
      {!!srText && <span className='mc-sr-only'>{srText}</span>}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={25}
        fill='none'
        viewBox='0 0 24 24'
        aria-hidden='true'
        role='img'
        className={newClassName}
        {...props}
      >
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M11.13 5.978c0-.48.39-.87.87-.87a8.696 8.696 0 1 1-8.695 8.696.87.87 0 0 1 1.739 0A6.957 6.957 0 1 0 12 6.848a.87.87 0 0 1-.87-.87'
          clipRule='evenodd'
        />
        <path fill='currentColor' d='M12 8.587 8.522 5.978 12 3.37z' />
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M12.39 2.592a.87.87 0 0 1 .48.778v5.217a.87.87 0 0 1-1.391.696L8 6.673a.87.87 0 0 1 0-1.39l3.479-2.61a.87.87 0 0 1 .91-.081M9.97 5.978l1.16.87v-1.74z'
          clipRule='evenodd'
        />
      </svg>
    </>
  )
}
IconReplayDefault.propTypes = {
  srText: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
} as PropTypes.ValidationMap<Props>
export default IconReplayDefault
