import React from 'react'

import { withI18nContext, useTranslation } from '@mc/i18n'
import {
  Button,
  IconFullScreenEnterDefault,
  IconFullScreenExitDefault,
} from '@mc/design-system'

import { useVideo } from '..'
import i18nInstance from '../../i18n/i18nInstance'
import { VideoControl } from './VideoControl'

/**
 * Displays an interactive "full screen" button
 */
export const VideoFullscreen = withI18nContext(i18nInstance)((
  props: React.ComponentProps<typeof Button>,
) => {
  const { fullscreen, toggleFullscreen } = useVideo()
  const { t } = useTranslation('@mc/video')

  let action
  let IconComponent

  if (fullscreen) {
    action = t('video.fullscreen.disable')
    IconComponent = IconFullScreenExitDefault
  } else {
    action = t('video.fullscreen.enable')
    IconComponent = IconFullScreenEnterDefault
  }

  return (
    <VideoControl>
      <Button
        onClick={toggleFullscreen}
        kind='link'
        size='small'
        symmetrical
        {...props}
      >
        <IconComponent srText={action} className='mc-icon mc-icon--scale-5' />
      </Button>
    </VideoControl>
  )
})
