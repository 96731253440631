import React from 'react'
import { defer } from 'lodash'

import { IconSettingsDefault } from '@mc/design-system'
import { useTranslation } from '@mc/i18n'
import { ControlDropdown } from './ControlDropdown'
import { useVideo } from '..'
import { useVideoSetting } from '../userSettings'
import { HDIcon } from './HDIcon'

/**
 * Displays an interactive Quality Level control
 *
 * Backed by localStorage
 */
export const VideoQualityLevel = () => {
  const {
    qualityLevelOptions,
    qualityUserSelection,
    setQualityLevelValue,
    qualityActual,
  } = useVideo()
  const { t } = useTranslation('@mc/video')

  const [savedQuality, setSavedQuality] = useVideoSetting('video_quality')

  if (qualityLevelOptions.length < 2) {
    return null
  }

  const savedOption = qualityLevelOptions.find(
    (value) => 'height' in value && value.height === savedQuality,
  )

  if (savedOption && savedOption.value !== qualityUserSelection.value) {
    defer(setQualityLevelValue, savedOption.value)
  }

  const handleClick = (newValue: number) => {
    const newOption = qualityLevelOptions.find(
      ({ value }) => value === newValue,
    )

    if (!newOption) {
      return
    }

    setSavedQuality('height' in newOption ? newOption.height : null)
    setQualityLevelValue(newOption.value)
  }

  return (
    <ControlDropdown
      title={t('video.quality.title')}
      icon={
        <span className='position-relative'>
          <IconSettingsDefault />
          {qualityActual?.hd && (
            <HDIcon
              className='position-absolute'
              style={{
                transform: 'translate(5px, -2px)',
              }}
            />
          )}
        </span>
      }
      selectedValue={qualityUserSelection.value}
      options={qualityLevelOptions.map((q) => ({
        label: q.name,
        value: q.value,
        icon: 'hd' in q && q.hd ? <HDIcon className='mc-ml-1' /> : null,
      }))}
      onClick={handleClick}
    />
  )
}
