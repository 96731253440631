import React from 'react'
import { bool, string, number } from 'prop-types'
import cn from 'classnames'
import { PROP_TYPE_CHILDREN } from '../constants'
import { PROP_TYPE_PLACEMENT } from '../Sticky/constants'

interface StickyControlledProps {
  show?: boolean
  placement?: string
  offset?: number
  className?: string
  children: React.ReactElement
  role?: string
  ariaLabel?: string
}

const StickyControlled = ({
  show = false,
  placement = 'bottom',
  offset = 0,
  className,
  children,
  ariaLabel,
  role,
}: StickyControlledProps) => {
  const classes = cn({
    'mc-sticky': true,
    [`mc-sticky--${placement}`]: true,
    'mc-sticky__show': show,
    [className ?? '']: !!className,
  })

  return (
    <div
      className={classes}
      style={{ [placement]: `${offset}px` }}
      aria-label={ariaLabel}
      role={role}
    >
      {children}
    </div>
  )
}

StickyControlled.propTypes = {
  show: bool,
  className: string,
  offset: number,
  placement: PROP_TYPE_PLACEMENT,
  children: PROP_TYPE_CHILDREN,
}

export default StickyControlled
