/* istanbul ignore file */
import * as React from 'react'
import type { SVGProps } from 'react'
import PropTypes from 'prop-types'

interface Props extends SVGProps<SVGSVGElement> {
  srText?: string
  size?: 'sm' | 'md' | 'lg' | 'scale-8' | 'scale-10' | number
  alt?: string
}
function IconNotificationDefault({
  srText,
  size = 'md',
  className = '',
  ...props
}: Props) {
  const newClassName = `mc-icon mc-icon--${size} ${className}`
  return (
    <>
      {!!srText && <span className='mc-sr-only'>{srText}</span>}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={25}
        fill='none'
        viewBox='0 0 24 24'
        aria-hidden='true'
        role='img'
        className={newClassName}
        {...props}
      >
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M12 4.487a4.945 4.945 0 0 0-4.945 4.945c0 3.395-.616 5.565-1.417 6.924h12.724c-.801-1.359-1.416-3.528-1.416-6.924A4.944 4.944 0 0 0 12 4.487m9.055 11.87c-.012-.004-.092-.028-.229-.125-.191-.136-.464-.398-.745-.87-.563-.95-1.149-2.734-1.149-5.93a6.932 6.932 0 1 0-13.864 0c0 3.192-.585 4.977-1.148 5.928-.28.473-.553.735-.745.872-.137.097-.217.12-.23.126a.993.993 0 0 0 .048 1.985h18.014a.993.993 0 0 0 .048-1.985M9.675 18.93a.993.993 0 0 1 1.209.717 1.152 1.152 0 0 0 2.232 0 .993.993 0 0 1 1.925.49 3.138 3.138 0 0 1-6.082 0 .993.993 0 0 1 .717-1.207'
          clipRule='evenodd'
        />
      </svg>
    </>
  )
}
IconNotificationDefault.propTypes = {
  srText: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
} as PropTypes.ValidationMap<Props>
export default IconNotificationDefault
