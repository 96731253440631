import React from 'react'

import { Modal, ModalClose, ModalContent } from '@mc/design-system'
import { useTranslation } from '@mc/i18n'
import { Terms } from '../Terms'

import { PersonaEmailCapture } from '../PersonaEmailCapture'

import classes from './MentorNotifyModal.module.scss'

interface MentorNotifyModalProps {
  isOpen: boolean
  onClose: () => void
  mentor: {
    slug: string
    name: string
    analytics?: { [key: string]: string | number | null | undefined }
  } | null
  onSubmit: (email: string, mentorSlug?: string) => void
  email?: string
}

export const MentorNotifyModal = ({
  isOpen,
  onClose,
  mentor,
  onSubmit,
  email,
}: MentorNotifyModalProps) => {
  const { t } = useTranslation('@mc/persona')

  const handleClose = () => {
    onClose()
  }

  const handleSubmit = (values: { email: string }) => {
    onClose()
    onSubmit(values.email, mentor?.slug)
  }

  return (
    <Modal
      show={isOpen}
      onClose={handleClose}
      backdrop='dark'
      className={classes.modal}
    >
      <ModalContent
        className={`mc-p-7 mc-p-md-6 mc-pb-md-9 mc-corners--lg ${classes.background}`}
      >
        <ModalClose className='mc-mt-md-4 mc-mr-md-4' />
        <div className=''>
          <h2 className='mc-text-h4 mc-text--center mc-mb-4 mc-mb-md-7 mc-mt-8 mc-mt-md-8'>
            {t('mentorNotify.modal.title', {
              mentor: mentor?.name,
            })}
          </h2>
          <PersonaEmailCapture
            className={`mc-theme-dark ${classes.emailCaptureForm}`}
            ctaText={t('mentorNotify.modal.cta')}
            terms={t('mentorNotify.modal.terms')}
            placeholder={t('mentorNotify.modal.placeholder')}
            showSuccess={false}
            onSubmit={handleSubmit}
            type='dark'
            email={email}
            extraPadding
            applyDisabledRules
            analytics={{
              cta: 'notify me',
              campaign: 'coming-soon-mentor',
              ...mentor?.analytics,
            }}
          />
          <Terms className={`mc-mt-4 ${classes.terms}`} ctaCopy='Submit' />
        </div>
      </ModalContent>
    </Modal>
  )
}
