import humps from "humps";
import { getEnvVar } from "@mc/client-env";
import { ApiError } from "./ApiError";
import type { ApiRequestOptions } from "./ApiRequestOptions";
import type { ApiResult } from "./ApiResult";
import { CancelablePromise } from "./CancelablePromise";
import type { OnCancel } from "./CancelablePromise";
import type { OpenAPIConfig } from "./OpenAPI";

export const isString = (value: unknown): value is string =>
  typeof value === "string";

export const isStringWithValue = (value: unknown): value is string =>
  isString(value) && value !== "";

export const isBlob = (value: unknown): value is Blob => value instanceof Blob;

export const isFormData = (value: unknown): value is FormData =>
  value instanceof FormData;

export const base64 = (str: string): string => {
  try {
    return btoa(str);
  } catch (err) {
    return Buffer.from(str).toString("base64");
  }
};

export const getQueryString = (params: Record<string, unknown>): string => {
  const qs: string[] = [];

  const append = (key: string, value: unknown) => {
    qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);
  };

  const encodePair = (key: string, value: unknown) => {
    if (value === undefined || value === null) {
      return;
    }

    if (value instanceof Date) {
      append(key, value.toISOString());
    } else if (Array.isArray(value)) {
      value.forEach((v) => encodePair(key, v));
    } else if (typeof value === "object") {
      Object.entries(value).forEach(([k, v]) => encodePair(`${key}[${k}]`, v));
    } else {
      append(key, value);
    }
  };

  Object.entries(params).forEach(([key, value]) => encodePair(key, value));

  return qs.length ? `?${qs.join("&")}` : "";
};

const getUrl = (config: OpenAPIConfig, options: ApiRequestOptions): string => {
  const encoder = config.ENCODE_PATH || encodeURI;

  const path = options.url
    .replace("{api-version}", config.VERSION)
    .replace(/{(.*?)}/g, (substring: string, group: string) => {
      if (Object.prototype.hasOwnProperty.call(options.path, group)) {
        return encoder(String(options.path?.[group]));
      }
      return substring;
    });

  const url = config.BASE + path;
  return options.query ? url + getQueryString(options.query) : url;
};

export const getFormData = (
  options: ApiRequestOptions
): FormData | undefined => {
  if (options.formData) {
    const formData = new FormData();

    const process = (key: string, value: unknown) => {
      if (isString(value) || isBlob(value)) {
        formData.append(humps.decamelize(key), value);
      } else {
        formData.append(humps.decamelize(key), JSON.stringify(value));
      }
    };

    Object.entries(options.formData)
      .filter(([, value]) => value !== undefined && value !== null)
      .forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((v) => process(key, v));
        } else {
          process(key, value);
        }
      });

    return formData;
  }
  return undefined;
};

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;

export const applyResolver = async <T>(
  options: ApiRequestOptions,
  resolver?: T | Resolver<T>
): Promise<T | undefined> => {
  if (typeof resolver === "function") {
    return (resolver as Resolver<T>)(options);
  }
  return resolver;
};

let csrfToken: string;

// Tree-shaking is basically not including any logic in api/src/index.ts
// moving this in to preserve default behaviors defined in line 11 - 31
const csrf = async () => {
  if (!csrfToken) {
    const response = await fetch("/api/v2/csrf-token");
    const { token } = await response.json();
    csrfToken = token;
  }
  return csrfToken;
};

export const getHeaders = async (
  config: OpenAPIConfig,
  options: ApiRequestOptions
): Promise<Headers> => {
  const [token, username, password, additionalHeaders] = await Promise.all([
    applyResolver(options, config.TOKEN),
    applyResolver(options, config.USERNAME),
    applyResolver(options, config.PASSWORD),
    applyResolver(options, config.HEADERS),
  ]);

  const contentType =
    options.mediaType ||
    options.headers?.["Content-Type"] ||
    "application/json";
  const profileUuid = localStorage.getItem("currentProfileUuid");
  const playbackToken = localStorage.getItem("currentPlaybackToken");

  const headers = Object.entries({
    Accept: "application/json",
    "X-Api-Key": getEnvVar("MEDIA_METADATA_API_KEY") as string,
    ...(options.method !== "GET" && { "X-CSRF-Token": await csrf() }),
    ...(profileUuid && { "MC-Profile-Id": profileUuid }),
    ...(playbackToken && { "X-PLAYBACK-TOKEN": playbackToken }),
    ...(options.mediaType !== "multipart/form-data" && {
      "Content-Type": contentType,
    }),
    ...additionalHeaders,
    ...options.headers,
  })
    .filter(([, value]) => value !== undefined && value !== null)
    .reduce(
      (hs, [key, value]) => ({
        ...hs,
        [key]: String(value),
      }),
      {} as Record<string, string>
    );

  if (isStringWithValue(token)) {
    headers.Authorization = `Bearer ${token}`;
  }

  if (isStringWithValue(username) && isStringWithValue(password)) {
    const credentials = base64(`${username}:${password}`);
    headers.Authorization = `Basic ${credentials}`;
  }

  if (options.body !== undefined) {
    if (options.mediaType) {
      headers["Content-Type"] = options.mediaType;
    } else if (isBlob(options.body)) {
      headers["Content-Type"] = options.body.type || "application/octet-stream";
    } else if (isString(options.body)) {
      headers["Content-Type"] = "text/plain";
    } else if (!isFormData(options.body)) {
      headers["Content-Type"] = "application/json";
    }
  }

  return new Headers(headers);
};

export const getRequestBody = (
  options: ApiRequestOptions
): BodyInit | undefined => {
  if (options.body !== undefined) {
    if (
      options.mediaType?.includes("application/json") ||
      options.mediaType?.includes("+json")
    ) {
      return JSON.stringify(humps.decamelizeKeys(options.body));
    } else if (
      isString(options.body) ||
      isBlob(options.body) ||
      isFormData(options.body)
    ) {
      return options.body;
    } else {
      return JSON.stringify(humps.decamelizeKeys(options.body));
    }
  }
  return undefined;
};

export const sendRequest = async (
  config: OpenAPIConfig,
  options: ApiRequestOptions,
  url: string,
  body: BodyInit | undefined,
  formData: FormData | undefined,
  headers: Headers,
  onCancel: OnCancel
): Promise<Response> => {
  const controller = new AbortController();

  let request: RequestInit = {
    headers,
    body: body ?? formData,
    method: options.method,
    signal: controller.signal,
  };

  if (config.WITH_CREDENTIALS) {
    request.credentials = config.CREDENTIALS;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const fn of config.interceptors.request._fns) {
    // eslint-disable-next-line no-await-in-loop
    request = await fn(request);
  }

  onCancel(() => controller.abort());

  return fetch(url, request);
};

export const getResponseHeader = (
  response: Response,
  responseHeader?: string
): string | undefined => {
  if (responseHeader) {
    const content = response.headers.get(responseHeader);
    if (isString(content)) {
      return content;
    }
  }
  return undefined;
};

export const getResponseBody = async (response: Response): Promise<unknown> => {
  if (response.status !== 204) {
    try {
      const contentType = response.headers.get("Content-Type");
      if (contentType) {
        const binaryTypes = [
          "application/octet-stream",
          "application/pdf",
          "application/zip",
          "audio/",
          "image/",
          "video/",
        ];
        if (
          contentType.includes("application/json") ||
          contentType.includes("+json")
        ) {
          return await response.json().then((res) => humps.camelizeKeys(res));
        } else if (binaryTypes.some((type) => contentType.includes(type))) {
          return await response.blob();
        } else if (contentType.includes("multipart/form-data")) {
          return await response.formData();
        } else if (contentType.includes("text/")) {
          return await response.text();
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
  return undefined;
};

export const catchErrorCodes = (
  options: ApiRequestOptions,
  result: ApiResult
): void => {
  const errors: Record<number, string> = {
    400: "Bad Request",
    401: "Unauthorized",
    402: "Payment Required",
    403: "Forbidden",
    404: "Not Found",
    405: "Method Not Allowed",
    406: "Not Acceptable",
    407: "Proxy Authentication Required",
    408: "Request Timeout",
    409: "Conflict",
    410: "Gone",
    411: "Length Required",
    412: "Precondition Failed",
    413: "Payload Too Large",
    414: "URI Too Long",
    415: "Unsupported Media Type",
    416: "Range Not Satisfiable",
    417: "Expectation Failed",
    418: "Im a teapot",
    421: "Misdirected Request",
    422: "Unprocessable Content",
    423: "Locked",
    424: "Failed Dependency",
    425: "Too Early",
    426: "Upgrade Required",
    428: "Precondition Required",
    429: "Too Many Requests",
    431: "Request Header Fields Too Large",
    451: "Unavailable For Legal Reasons",
    500: "Internal Server Error",
    501: "Not Implemented",
    502: "Bad Gateway",
    503: "Service Unavailable",
    504: "Gateway Timeout",
    505: "HTTP Version Not Supported",
    506: "Variant Also Negotiates",
    507: "Insufficient Storage",
    508: "Loop Detected",
    510: "Not Extended",
    511: "Network Authentication Required",
    ...options.errors,
  };

  const error = errors[result.status];
  if (error) {
    throw new ApiError(options, result, error);
  }

  if (!result.ok) {
    const errorStatus = result.status ?? "unknown";
    const errorStatusText = result.statusText ?? "unknown";
    const errorBody = (() => {
      try {
        return JSON.stringify(result.body, null, 2);
      } catch (e) {
        return undefined;
      }
    })();

    throw new ApiError(
      options,
      result,
      `Generic Error: status: ${errorStatus}; status text: ${errorStatusText}; body: ${errorBody}`
    );
  }
};

/**
 * Request method
 * @param config The OpenAPI configuration object
 * @param options The request options from the service
 * @returns CancelablePromise<T>
 * @throws ApiError
 */
export const request = <T>(
  config: OpenAPIConfig,
  options: ApiRequestOptions
): CancelablePromise<T> =>
  new CancelablePromise(async (resolve, reject, onCancel) => {
    try {
      const url = getUrl(config, options);
      const formData = getFormData(options);
      const body = getRequestBody(options);
      const headers = await getHeaders(config, options);

      if (!onCancel.isCancelled) {
        let response = await sendRequest(
          config,
          options,
          url,
          body,
          formData,
          headers,
          onCancel
        );

        // eslint-disable-next-line no-restricted-syntax
        for (const fn of config.interceptors.response._fns) {
          // eslint-disable-next-line no-await-in-loop
          response = await fn(response);
        }

        const responseBody = await getResponseBody(response);
        const responseHeader = getResponseHeader(
          response,
          options.responseHeader
        );

        const result: ApiResult = {
          url,
          ok: response.ok,
          status: response.status,
          statusText: response.statusText,
          body: responseHeader ?? responseBody,
        };

        catchErrorCodes(options, result);

        resolve(result.body);
      }
    } catch (error) {
      reject(error);
    }
  });
