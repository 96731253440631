import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { useTranslation } from '@mc/i18n'
import { Badge, Button } from '@mc/design-system'

import { useCtaToPurchaseUrl } from '../../utils/cta'
import { TrackingSectionContainer } from '../../components'
import { usePersonaEvents } from '../../events'
import { useNonCommerceGoldStandardEvents } from '../../hooks/tracking/useNonCommerceGoldStandardEvents'
import type { Locations, PageSources } from '../../types/tracking'
import { useTiers } from '../../components/TierProvider/TierProvider'
import {
  type ExtendedSources,
  getPageSource,
} from '../../hooks/tracking/events'
import { useAuth0User } from '../../hooks/useAuth0User'

import { ROW_TRACKING } from './utils'

type PlanProps = {
  title: string
  desc: string
  badge?: string
}

const Plan = ({ title, desc, badge }: PlanProps) => (
  <div className='d-flex flex-1 flex-column mc-corners--lg mc-p-6 justify-content-end fancy-background'>
    {badge && (
      <div>
        <Badge className='mc-corners--sm mc-text--uppercase'>{badge}</Badge>
      </div>
    )}
    <div>
      <h3 className='mc-text-h3 mc-my-2'>{title}</h3>
      <p className='mc-text-color--medium'>{desc}</p>
    </div>
  </div>
)

export const PlansSection = ({
  ctaUrl,
  rowAnalytics = ROW_TRACKING.plans,
  source,
}: {
  ctaUrl: string
  rowAnalytics?: {
    rowName: string
    rowPositionIndex: number
  }
  source?: PageSources
}) => {
  const { t } = useTranslation('@mc/persona')
  const getCtaToPurchaseUrl = useCtaToPurchaseUrl()
  const { trackPersonaSignClick } = usePersonaEvents()
  const { trackPurchaseClick } = useNonCommerceGoldStandardEvents(source)
  const { annualTier, monthlyTier } = useTiers()
  const { slug: instructorSlug } = useRouter().query
  const { user } = useAuth0User()

  const handleCTAClick = () => {
    let sourceResult
    if (source === 'bio') {
      sourceResult = user ? 'bio' : 'bioNoUser'
    } else {
      sourceResult = source
    }
    const pageSource = getPageSource(
      sourceResult as ExtendedSources,
      instructorSlug as string | undefined,
    )
    const trackProps = {
      cta: 'sign up',
      location: rowAnalytics.rowName as Locations,
    }
    trackPersonaSignClick({
      ...trackProps,
      additionalAnalytics: { pageSource },
    })
    trackPurchaseClick({ ...trackProps, pageSource })
  }

  return (
    <TrackingSectionContainer analytics={rowAnalytics}>
      <div className='container d-flex justify-content-center mc-py-10'>
        <div className='d-flex flex-column justify-content-center col-12 col-md-10 col-lg-8'>
          <h2 className='mc-text-h1 mc-text--center mc-mx-auto mc-mb-9'>
            {t('landing.plans.title')}
          </h2>
          <div className='d-flex flex-gap-4 flex-column flex-sm-row justify-content-center'>
            <Plan
              title={t('landing.plans.annual.title')}
              desc={
                annualTier
                  ? t('landing.plans.annual.desc', {
                      priceWithCurrencySymbol:
                        annualTier.internalData.prices.fullMonthlyWithCurrency,
                    })
                  : t('landing.plans.annual.descFallback')
              }
              badge={t('landing.plans.annual.badge')}
            />
            <Plan
              title={t('landing.plans.monthly.title')}
              desc={
                monthlyTier
                  ? t('landing.plans.monthly.desc', {
                      priceWithCurrencySymbol:
                        monthlyTier.internalData.prices.fullMonthlyWithCurrency,
                    })
                  : t('landing.plans.monthly.descFallback')
              }
            />
          </div>
          <div className='mc-text--center'>
            <Button
              className='mc-mt-9 mc-px-11 mc-py-4 mc-corners--12'
              as={Link}
              href={getCtaToPurchaseUrl(ctaUrl, 'discovery')}
              onClick={handleCTAClick}
            >
              {t('landing.plans.cta')}
            </Button>
          </div>
        </div>
      </div>
    </TrackingSectionContainer>
  )
}
