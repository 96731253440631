import React, { CSSProperties, useState } from 'react'
import { useVideo } from '../VideoContext'

const TEXT_ALIGNMENT: Record<LineAlignSetting, string> = {
  start: 'mc-text--left',
  center: 'mc-text--center',
  end: 'mc-text--right',
}

const VideoTrackLine = ({
  cue,
  fontStyles,
}: {
  cue: VTTCue
  fontStyles: CSSProperties
}) => {
  const alignment: CSSProperties =
    cue.line === 'auto'
      ? { bottom: 'var(--mc-scale-6)' }
      : { top: `${cue.line}%` }

  return (
    <div
      style={{ ...alignment }}
      className='position-absolute mc-theme-dark d-flex justify-content-center mc-w-100'
    >
      <div className={TEXT_ALIGNMENT[cue.lineAlign ?? 'center']}>
        <span
          style={fontStyles}
          className='mc-background--color-dim'
          data-testid='video-caption'
        >
          {cue.text?.split('\n').map((line, i) => (
            <React.Fragment key={line + i}>
              <span dangerouslySetInnerHTML={{ __html: line }} />
              <br />
            </React.Fragment>
          ))}
        </span>
      </div>
    </div>
  )
}

export const VideoTrack = () => {
  const { textTrackCues } = useVideo()

  const [width, setWidth] = useState(0)

  const fontStyles =
    width > 600
      ? {
          fontSize: 'var(--mc-scale-7)',
          lineHeight: 1.2,
        }
      : {
          fontSize: 'var(--mc-scale-4)',
          lineHeight: 1.2,
        }

  if (!textTrackCues || textTrackCues.length === 0) return null

  return (
    <div
      ref={(el) => setWidth(el?.parentElement?.clientWidth ?? 0)}
      style={{ inset: 0, pointerEvents: 'none', userSelect: 'none' }}
      className='d-flex flex-column position-absolute mc-video--text-track'
    >
      {textTrackCues.map((cue, i) => (
        <VideoTrackLine
          cue={cue}
          fontStyles={fontStyles}
          key={`${cue.text}-${i}`}
        />
      ))}
    </div>
  )
}
