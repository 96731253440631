/* istanbul ignore file */
import * as React from 'react'
import type { SVGProps } from 'react'
import PropTypes from 'prop-types'

interface Props extends SVGProps<SVGSVGElement> {
  srText?: string
  size?: 'sm' | 'md' | 'lg' | 'scale-8' | 'scale-10' | number
  alt?: string
}
function IconPauseDefault({
  srText,
  size = 'md',
  className = '',
  ...props
}: Props) {
  const newClassName = `mc-icon mc-icon--${size} ${className}`
  return (
    <>
      {!!srText && <span className='mc-sr-only'>{srText}</span>}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={25}
        fill='none'
        viewBox='0 0 24 24'
        aria-hidden='true'
        role='img'
        className={newClassName}
        {...props}
      >
        <path fill='currentColor' d='M5.143 21.25V3.75h2.285v17.5z' />
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M4 3.75c0-.69.512-1.25 1.143-1.25h2.286c.63 0 1.142.56 1.142 1.25v17.5c0 .69-.511 1.25-1.142 1.25H5.143C4.512 22.5 4 21.94 4 21.25z'
          clipRule='evenodd'
        />
        <path fill='currentColor' d='M16.571 21.25V3.75h2.286v17.5z' />
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M15.429 3.75c0-.69.511-1.25 1.143-1.25h2.285c.631 0 1.143.56 1.143 1.25v17.5c0 .69-.512 1.25-1.143 1.25h-2.285c-.632 0-1.143-.56-1.143-1.25z'
          clipRule='evenodd'
        />
      </svg>
    </>
  )
}
IconPauseDefault.propTypes = {
  srText: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
} as PropTypes.ValidationMap<Props>
export default IconPauseDefault
