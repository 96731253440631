import React from 'react'
import { Form } from 'react-final-form'

import { withI18nContext } from '@mc/i18n'

import { getEmailFormValidator } from '../helpers'
import { i18nInstance } from '../i18n/i18nInstance'
import { EmailCaptureFormValues } from '../types'

interface EmailCaptureFormProps {
  className?: string
  children: React.ReactNode
  onSubmit: (data: EmailCaptureFormValues) => void
  isGdprConsentRequired?: boolean
  noValidate?: boolean
}

const EmailCaptureFormComponent = ({
  children,
  className = '',
  onSubmit,
  isGdprConsentRequired = false,
  noValidate = false,
}: EmailCaptureFormProps) => (
  <Form
    validate={getEmailFormValidator({ isGdprConsentRequired })}
    onSubmit={onSubmit}
    render={({ handleSubmit }) => (
      <form
        className={className}
        onSubmit={handleSubmit}
        noValidate={noValidate}
      >
        {children}
      </form>
    )}
  />
)

export const EmailCaptureForm = withI18nContext(i18nInstance)(
  EmailCaptureFormComponent,
)
