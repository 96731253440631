import React from 'react'

const hijackEvent: React.MouseEventHandler<HTMLDivElement> = (event) =>
  event.stopPropagation()

export const VideoContainer = (props: React.HTMLAttributes<HTMLDivElement>) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  <div
    className='mc-video__controls mc-theme-dark mc-text-small mc-px-2'
    onClick={hijackEvent}
    onDoubleClick={hijackEvent}
    {...props}
  />
)
