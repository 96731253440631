import React, { useEffect, useState } from 'react'
import {
  TrackingContext,
  TrackingParams,
  useTrackingContext,
} from '@mc/tracking-context'
import {
  TRACK_PROPS,
  createOrGetVisitSessionIdFromCookie,
  createSessionId,
  getVisitSessionId,
} from '@mc/track-event'
import { isEqual, pick } from 'lodash'

export interface MediaEventsState {
  [TRACK_PROPS.PLAYER_SESSION_ID]?: string
  [TRACK_PROPS.MEDIA_SESSION_ID]?: string
  [TRACK_PROPS.VISIT_SESSION_ID]?: string
}

export interface MediaEventsProviderProps {
  children?: React.ReactNode
}

const contentParams = (props: TrackingParams) =>
  pick(props, ['content_id', 'content_title', 'content_type'])

/**
 * Provides and manages the Visit Session UUID trio:
 * - visit_session_id
 * - player_session_id
 * - media_session_id
 */
export const VisitSessionProvider = ({
  children,
}: MediaEventsProviderProps) => {
  const [session, setSession] = useState(() => initializeSession())

  const trackingParams = useTrackingContext()
  const [oldTrackingState, setOldTrackingState] = useState(
    contentParams(trackingParams),
  )

  const currentSessionId = getVisitSessionId()

  useEffect(() => {
    if (currentSessionId !== session.visit_session_id) {
      const newSession = initializeSession()
      setSession(newSession)
    }
  }, [currentSessionId, session.visit_session_id])

  if (
    !isEqual(contentParams(trackingParams), contentParams(oldTrackingState))
  ) {
    setSession({
      ...session,
      media_session_id: createSessionId(),
    })
    setOldTrackingState(trackingParams)
  }

  return (
    <TrackingContext.Provider
      value={{
        ...trackingParams,
        ...session,
      }}
    >
      {children}
    </TrackingContext.Provider>
  )
}

const initializeSession = () => ({
  [TRACK_PROPS.VISIT_SESSION_ID]: createOrGetVisitSessionIdFromCookie(),
  [TRACK_PROPS.PLAYER_SESSION_ID]: createSessionId(),
  [TRACK_PROPS.MEDIA_SESSION_ID]: createSessionId(),
})
