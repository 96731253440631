/* istanbul ignore file */
import * as React from 'react'
import type { SVGProps } from 'react'
import PropTypes from 'prop-types'

interface Props extends SVGProps<SVGSVGElement> {
  srText?: string
  size?: 'sm' | 'md' | 'lg' | 'scale-8' | 'scale-10' | number
  alt?: string
}
function IconFullScreenExitDefault({
  srText,
  size = 'md',
  className = '',
  ...props
}: Props) {
  const newClassName = `mc-icon mc-icon--${size} ${className}`
  return (
    <>
      {!!srText && <span className='mc-sr-only'>{srText}</span>}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={25}
        fill='none'
        viewBox='0 0 24 24'
        aria-hidden='true'
        role='img'
        className={newClassName}
        {...props}
      >
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M15 4.5a1 1 0 0 1 1 1v3h5a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1M14 15.5a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-5v3a1 1 0 1 1-2 0zM2 15.5a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-3H3a1 1 0 0 1-1-1M9 4.5a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H3a1 1 0 1 1 0-2h5v-3a1 1 0 0 1 1-1'
          clipRule='evenodd'
        />
      </svg>
    </>
  )
}
IconFullScreenExitDefault.propTypes = {
  srText: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
} as PropTypes.ValidationMap<Props>
export default IconFullScreenExitDefault
