import React, { useState } from 'react'

import { useTranslation } from '@mc/i18n'
import {
  Button,
  IconChevronDownDefault,
  IconChevronUpDefault,
} from '@mc/design-system'
import { TrackingContainer } from '@mc/visibility'

import { MentorNotifyModal } from '..'
import { usePersonaEvents } from '../../events'

import { ComingSoonMentorCard } from './ComingSoonMentorCard'
import { type Mentor } from '../../types/mentor'
import { useIsUpcomingBetaEnabled } from '../../experiments'

const MAX_COMING_SOON_MENTORS = 50

interface ComingSoonSectionProps {
  mentorList: Mentor[]
  comingSoonRowAnalytics: {
    rowName: string
    rowPositionIndex: number
  }
}

interface InstructorBasicData {
  slug: string
  name: string
  analytics: { [key: string]: string | number | null | undefined }
}

export const ComingSoonMentors = ({
  mentorList,
  comingSoonRowAnalytics,
}: ComingSoonSectionProps) => {
  const { t } = useTranslation('@mc/persona')
  const {
    trackPersonaRowTileViewed,
    trackPersonaRowTileClicked,
    trackPersonaEmailCaptured,
  } = usePersonaEvents()
  const [showingMentors, setShowingMentors] = useState(
    mentorList.slice(0, MAX_COMING_SOON_MENTORS),
  )
  const [showMore, setShowMore] = useState(false)
  const handleShowMore = () => {
    setShowingMentors(
      showMore ? mentorList.slice(0, MAX_COMING_SOON_MENTORS) : mentorList,
    )
    setShowMore(!showMore)
  }
  const isUpcomingBeta = useIsUpcomingBetaEnabled()

  const [notifiedMentors, setNotifiedMentors] = useState<
    Record<string, boolean>
  >({})

  const [notifyEmail, setNotifyEmail] = useState('')
  const [mentorNotifyModal, setMentorNotifyModal] =
    useState<InstructorBasicData | null>(null)

  const handleClickNotify = (instructor: InstructorBasicData) => {
    if (!notifyEmail) {
      setMentorNotifyModal(instructor)
    } else {
      trackPersonaEmailCaptured({
        cta: 'notify me',
        email: notifyEmail,
        additionalAnalytics: {
          isUpcomingBeta,
          ...instructor.analytics,
        },
      })
      setNotifiedMentors((prev) => ({ ...prev, [instructor.slug]: true }))
    }
  }

  const handleVisible = (mentor: Mentor, position: number) => () =>
    trackPersonaRowTileViewed({
      ...comingSoonRowAnalytics,
      tilePositionIndex: position.toString(),
      tileTitle: mentor.title,
      tileSubtitle: mentor.keywords.join('  •  '),
      tileInstructorSlug: mentor.slug,
    })

  const handleClick = (mentor: Mentor, i: number) => () => {
    trackPersonaRowTileClicked({
      ...comingSoonRowAnalytics,
      tilePositionIndex: i.toString(),
      tileTitle: mentor.title,
      tileSubtitle: mentor.keywords.join('  •  '),
      tileLink: mentor.redirectUrl || '',
      tileInstructorSlug: mentor.slug,
    })
    handleClickNotify({
      name: mentor.title,
      slug: mentor.slug,
      analytics: {
        campaign: 'coming-soon-expert',
        rowPositionIndex: comingSoonRowAnalytics.rowPositionIndex.toString(),
        rowName: comingSoonRowAnalytics.rowName,
        subrowSubpositionIndex: i.toString(),
        tilePositionIndex: i.toString(),
        tileTitle: mentor.title,
        tileSubtitle: mentor.keywords.join('  •  '),
        tileInstructorSlug: mentor.slug,
        tileLink: null,
        tileClickType: 'thumbnail',
      },
    })
  }

  const handleNotifySubmit = (email: string, mentorSlug?: string) => {
    setNotifyEmail(email)
    if (mentorSlug)
      setNotifiedMentors((prev) => ({ ...prev, [mentorSlug]: true }))
  }

  return (
    <>
      <div className='d-flex flex-column align-items-center justify-content-center'>
        <div className='d-flex flex-gap-8 flex-wrap align-items-center justify-content-center'>
          {showingMentors.map((mentor, i) => (
            <TrackingContainer
              config={{ threshold: 0.3 }}
              key={`${mentor.title}-${i}`}
              className='position-relative'
              onVisible={handleVisible(mentor, i)}
            >
              <ComingSoonMentorCard
                mentor={mentor}
                onClickNotify={handleClick(mentor, i)}
                isNotified={notifiedMentors[mentor.slug]}
              />
            </TrackingContainer>
          ))}
        </div>
        {mentorList.length > MAX_COMING_SOON_MENTORS && (
          <Button
            className='fancy-background mc-py-4 mc-px-8 flex-gap-1 mc-mt-9 mc-corners--12 d-flex align-items-center'
            onClick={handleShowMore}
          >
            {t(`common.${showMore ? 'showLess' : 'showMore'}`)}
            {showMore ? (
              <IconChevronUpDefault size='lg' />
            ) : (
              <IconChevronDownDefault size='lg' />
            )}
          </Button>
        )}
      </div>
      <MentorNotifyModal
        isOpen={!!mentorNotifyModal}
        onClose={() => setMentorNotifyModal(null)}
        mentor={mentorNotifyModal}
        email={notifyEmail}
        onSubmit={(email, mentor) => handleNotifySubmit(email, mentor)}
      />
    </>
  )
}
