import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

const FullscreenContext = React.createContext({
  fullscreen: false,
  setFullscreen: (_: boolean) => {},
  toggleFullscreen: () => {},
})

export const useFullscreen = () => useContext(FullscreenContext)

const requestFullscreen = (container: HTMLDivElement) => {
  if (container.requestFullscreen) {
    container.requestFullscreen()
  } else if (container.webkitRequestFullscreen) {
    container.webkitRequestFullscreen()
  } else if (container.msRequestFullscreen) {
    container.msRequestFullscreen()
  }
}

const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen()
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen()
  }
}

/**
 * Permits entering full-screen mode, during which custom overlays can be rendered.
 * Note: iOS Safari does not permit custom controls or overlays in fullscreen, so the
 * `fullscreen` field of the VideoContext will always be `false` on iOS Safari.
 *
 * See: https://developer.apple.com/documentation/webkitjs/htmlvideoelement/1628805-webkitsupportsfullscreen
 */
export const FullscreenContainer = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [fullscreen, setFullscreen] = useState(false)

  const handleFullscreenChange = () => {
    setFullscreen(Boolean(document.fullscreenElement))
  }

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange)

    return () =>
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
  }, [])

  const toggleFullscreen = useCallback(() => {
    if (!fullscreen) {
      if (!containerRef.current) return
      requestFullscreen(containerRef.current)
      setFullscreen(true)
    } else {
      exitFullscreen()
      setFullscreen(false)
    }
  }, [containerRef, fullscreen, setFullscreen])

  return (
    <div
      ref={containerRef}
      onDoubleClick={() => toggleFullscreen()}
      className={[
        fullscreen ? 'mc-video--fullscreen' : 'mc-video--windowed',
        className,
      ].join(' ')}
      role='region'
    >
      <FullscreenContext.Provider
        value={{
          fullscreen,
          setFullscreen,
          toggleFullscreen,
        }}
      >
        {children}
      </FullscreenContext.Provider>
    </div>
  )
}
