/* istanbul ignore file */
import * as React from 'react'
import type { SVGProps } from 'react'
import PropTypes from 'prop-types'

interface Props extends SVGProps<SVGSVGElement> {
  srText?: string
  size?: 'sm' | 'md' | 'lg' | 'scale-8' | 'scale-10' | number
  alt?: string
}
function IconHeadphonesDefault({
  srText,
  size = 'md',
  className = '',
  ...props
}: Props) {
  const newClassName = `mc-icon mc-icon--${size} ${className}`
  return (
    <>
      {!!srText && <span className='mc-sr-only'>{srText}</span>}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={25}
        fill='none'
        viewBox='0 0 24 24'
        aria-hidden='true'
        role='img'
        className={newClassName}
        {...props}
      >
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M22 12.51C22 6.98 17.523 2.5 12 2.5S2 6.981 2 12.51c0 1.2.211 2.352.599 3.418A4.36 4.36 0 0 0 2 18.141c0 3.035 2.28 4.323 4.975 4.338a.63.63 0 0 0 .625-.626v-7.507a.63.63 0 0 0-.625-.626c-1.14.082-1.915.07-2.939.72a8 8 0 0 1-.161-1.617C3.875 8.331 7.513 4.69 12 4.69s8.125 3.641 8.125 8.133a8 8 0 0 1-.16 1.617c-1.04-.66-1.788-.636-2.943-.7a.63.63 0 0 0-.625.627v7.507c0 .344.282.626.625.626C19.71 22.44 22 21.197 22 18.141c0-.807-.218-1.563-.599-2.212a10 10 0 0 0 .599-3.42'
          clipRule='evenodd'
        />
      </svg>
    </>
  )
}
IconHeadphonesDefault.propTypes = {
  srText: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
} as PropTypes.ValidationMap<Props>
export default IconHeadphonesDefault
