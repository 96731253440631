import { createContext, useContext } from 'react'

type TrackProperties = Record<
  string,
  string | number | boolean | object | null | undefined
>

type TrackOptions = { integrations?: Record<string, boolean> }

type TrackFn = (
  event: string,
  properties?: TrackProperties,
  options?: TrackOptions,
) => void

type IdentifyFn = (
  userId?: string | number | null,
  traits?: Record<string, unknown>,
  options?: SegmentAnalytics.SegmentOpts,
) => void

export type TrackPageFn = (
  event?: string,
  properties?: TrackProperties,
  options?: TrackOptions,
) => Promise<void>

export type SegmentTrackingContextType = {
  identify: IdentifyFn
  track: TrackFn
  trackPage: TrackPageFn
  resetSegmentIdentity: () => void
  trackExperimentViewed: (
    experimentName: string,
    properties?: TrackProperties,
  ) => void
  trackExperimentBucketed: (
    experimentName: string,
    properties?: TrackProperties,
  ) => void
}

export const SegmentTrackingContext =
  createContext<SegmentTrackingContextType | null>(null)

export const useSegmentTracking = () => {
  const ctx = useContext(SegmentTrackingContext)
  if (!ctx)
    throw new Error(
      'useSegmentTracking must be used within a SegmentTrackingProvider',
    )
  return ctx
}
