import { useEffect } from 'react'
import { defer } from 'lodash'

import { useVideo } from '..'

const SEEK_AMOUNT = 5
const VOLUME_AMOUNT = 0.1

/**
 * Captures and handles keyboard interactions when the Video is focused
 * Interactions:
 * - Space = play
 * - ArrowUp = increase volume 10%
 * - ArrowDown = decrease volume 10%
 * - ArrowRight = seek forward 5 seconds (30 seconds with shift key)
 * - ArrowLeft = seek backward 5 seconds (30 seconds with shift key)
 * - M = mute/unmute
 */
export const VideoKeyboard = ({ el }: { el: HTMLElement | null }) => {
  const {
    active,
    time,
    volume,

    seekingTo,
    seekTo,
    setVolume,
    toggleMute,
    togglePlay,
  } = useVideo()

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!active) return

      switch (event.code) {
        case 'Space':
          event.preventDefault()
          togglePlay()
          break
        case 'ArrowRight': {
          event.preventDefault()
          const newTime =
            time + (event.shiftKey ? SEEK_AMOUNT * 6 : SEEK_AMOUNT)
          seekingTo(newTime)
          defer(seekTo, newTime)
          break
        }
        case 'ArrowLeft': {
          event.preventDefault()
          const newTime =
            time + (event.shiftKey ? -SEEK_AMOUNT * 6 : -SEEK_AMOUNT)
          seekingTo(newTime)
          defer(seekTo, newTime)
          break
        }
        case 'ArrowUp':
          event.preventDefault()
          setVolume(volume + VOLUME_AMOUNT)
          break
        case 'ArrowDown':
          event.preventDefault()
          setVolume(volume - VOLUME_AMOUNT)
          break
        case 'KeyM':
          event.preventDefault()
          toggleMute()
          break
        default:
          break
      }
    }

    el?.addEventListener('keydown', handleKeyDown)

    return () => el?.removeEventListener('keydown', handleKeyDown)
  }, [
    active,
    togglePlay,
    toggleMute,
    el,
    seekingTo,
    seekTo,
    time,
    setVolume,
    volume,
  ])

  return null
}
