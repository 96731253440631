import React from 'react'

import { withI18nContext, useTranslation } from '@mc/i18n'

import { useVideo } from '..'
import i18nInstance from '../../i18n/i18nInstance'
import { VideoControl } from './VideoControl'

const toTime = (seconds: number) => {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = date.getSeconds()

  if (hh) {
    return `${hh}:${mm.toString().padStart(2, '0')}:${ss
      .toString()
      .padStart(2, '0')}`
  }

  return `${mm}:${ss.toString().padStart(2, '0')}`
}

/**
 * Displays the current time and duration
 */
export const VideoTime = withI18nContext(i18nInstance)(() => {
  const { duration, time } = useVideo()
  const { t } = useTranslation('@mc/video')

  return (
    <VideoControl className='mc-video__time'>
      <span>
        {t('video.time', { time: toTime(time), duration: toTime(duration) })}
      </span>
    </VideoControl>
  )
})
