import React, { useState } from 'react'
import { Field, useFormState } from 'react-final-form'
import cn from 'classnames'

import { Button, CheckboxField } from '@mc/design-system'
import {
  EmailCaptureForm,
  EmailCaptureFormValues,
  EmailCaptureInput,
} from '@mc/email-capture'
import { useTranslation } from '@mc/i18n'

import { usePersonaEvents } from '../../events'
import { useIsUpcomingBetaEnabled } from '../../experiments'

import classes from './PersonaEmailCapture.module.scss'

interface PersonaEmailCaptureProps {
  className?: string
  onSubmit?: (values: EmailCaptureFormValues) => void
  analytics?: Record<string, string | number | undefined>
  ctaText?: string
  terms?: string
  placeholder?: string
  showSuccess?: boolean
  applyDisabledRules?: boolean
  type?: 'light' | 'dark'
  email?: string
  extraPadding?: boolean
}

const SubmitButton = ({
  ctaText,
  isSuccess,
  applyDisabledRules,
}: {
  ctaText: string
  isSuccess: boolean
  applyDisabledRules: boolean
}) => {
  const { pristine, hasValidationErrors } = useFormState()
  return (
    <Button
      type='submit'
      disabled={
        isSuccess ||
        (applyDisabledRules &&
          ((pristine && hasValidationErrors) || hasValidationErrors))
      }
      className={`${classes.ctaButton} mc-ml-3`}
    >
      {ctaText}
    </Button>
  )
}

export const PersonaEmailCapture = ({
  className,
  onSubmit,
  analytics,
  ctaText,
  terms,
  showSuccess = true,
  applyDisabledRules = false,
  placeholder,
  type = 'light',
  email,
  extraPadding = false,
  ...props
}: PersonaEmailCaptureProps) => {
  const { t } = useTranslation('@mc/persona')
  const { trackPersonaEmailCaptured } = usePersonaEvents()
  const [success, setSuccess] = useState(false)
  const [showValidation, setShowValidation] = useState(false)
  const isUpcomingBeta = useIsUpcomingBetaEnabled()

  const handleSubmit = (values: EmailCaptureFormValues) => {
    setShowValidation(true)

    if (!values.email || !values.terms) {
      return
    }

    const trackProps = {
      cta: (analytics?.cta || 'join waitlist') as string,
      isUpcomingBeta,
      campaign: (analytics?.campaign || 'upcoming-beta') as string,
      email: values.email,
    }

    trackPersonaEmailCaptured({
      ...trackProps,
      additionalAnalytics: analytics,
    })
    setSuccess(true)
    onSubmit?.(values)
  }

  if (success && showSuccess) {
    return (
      <div className={classes.thankYou}>
        <p className='mc-text-h6'>
          {t('upcomingBetaLanding.hero.emailCapture.thankYou')}
        </p>
      </div>
    )
  }

  return (
    <EmailCaptureForm
      onSubmit={handleSubmit}
      className={`mc-theme-light ${className}`}
      noValidate
      {...props}
    >
      <div className='d-flex flex-column align-items-center'>
        <div className='d-flex mc-w-100'>
          <EmailCaptureInput
            showLabel={false}
            className={`${classes.emailCapture} ${classes[`emailCapture--${type}`]}`}
            label={t('upcomingBetaLanding.hero.emailCapture.label')}
            placeholder={
              placeholder ||
              t('upcomingBetaLanding.hero.emailCapture.placeholder')
            }
            initialValue={email}
          />
          <SubmitButton
            ctaText={ctaText || t('upcomingBetaLanding.hero.emailCapture.cta')}
            isSuccess={success}
            applyDisabledRules={applyDisabledRules}
          />
        </div>
        <div className={cn('mc-w-100', extraPadding ? 'mc-mt-4' : 'mc-mt-3')}>
          <Field
            id='terms'
            name='terms'
            component={CheckboxField}
            className={`${classes.termsCheckbox} ${classes[`termsCheckbox--${type}`]}`}
            label={
              <span className='mc-theme-dark mc-text-x-small'>
                {terms || t('upcomingBetaLanding.hero.emailCapture.terms')}
              </span>
            }
            validate={(value) =>
              value
                ? undefined
                : t('upcomingBetaLanding.hero.emailCapture.termsError')
            }
            required
            showError={showValidation}
            initialValue={email ? true : undefined}
          />
        </div>
      </div>
    </EmailCaptureForm>
  )
}
