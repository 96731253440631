import React from 'react'

const VideoScrubberBubble = ({
  children,
  x,
}: {
  children: React.ReactNode
  x: number
}) => (
  <div
    className='position-absolute'
    style={{ bottom: 0, left: x, transform: 'translateX(-50%)' }}
  >
    <div
      className='mc-theme--dark mc-tooltip__content mc-p-3'
      style={{ borderWidth: 0 }}
    >
      {children}
    </div>
  </div>
)

interface Props {
  /**
   * The content to display
   */
  children: React.ReactNode
  /**
   * Whether to show the popup
   */
  show: boolean
  /**
   * X-position of the center of the popup
   */
  x: number
}

/**
 * Displays arbitrary content in a popup
 */
export const VideoScrubberPopup = ({ children, show, x }: Props) =>
  show ? (
    <div className='position-relative'>
      <VideoScrubberBubble x={x}>{children}</VideoScrubberBubble>
    </div>
  ) : null
