import React from 'react'

import { withI18nContext, useTranslation } from '@mc/i18n'
import { Loader } from '@mc/design-system'

import { useVideo } from '.'
import i18nInstance from '../i18n/i18nInstance'

interface VideoLoaderProps {
  customLoader?: React.ReactNode
}

/**
 * Displays a loading indicator when waiting
 */
export const VideoLoader = withI18nContext(i18nInstance)(({
  customLoader,
}: VideoLoaderProps) => {
  const { waiting, readyState } = useVideo()
  const { t } = useTranslation('@mc/video')

  if (waiting || readyState === 'loading') {
    return (
      customLoader || (
        <div className='mc-video__loader'>
          <Loader />
          <div className='mc-sr-only'>{t('video.loading')}</div>
        </div>
      )
    )
  }

  return <></>
})
