import React, { PureComponent } from 'react'
import cn from 'classnames'
import { FieldInputProps } from 'react-final-form'

import { PROP_TYPE_INPUT } from '../Forms/constants'
import { getState } from '../Forms/utils'

type InputProps = Partial<
  FieldInputProps<string, HTMLInputElement | HTMLTextAreaElement>
>

export default class Input extends PureComponent<InputProps> {
  static propTypes = {
    ...PROP_TYPE_INPUT,
  }

  static defaultProps = {
    value: '',
    size: 'md',
    multiline: false,
  }

  state = {
    focused: false,
    multilineHeight: 'auto',
  }

  private input: React.RefObject<HTMLInputElement | HTMLTextAreaElement>

  constructor(props: InputProps) {
    super(props)

    this.input = props.inputRef || React.createRef()
  }

  componentDidMount() {
    if (this.props.multiline) {
      this.resize()
    }
  }

  componentDidUpdate(
    prevProps: Readonly<
      Partial<FieldInputProps<string, HTMLInputElement | HTMLTextAreaElement>>
    >,
  ): void {
    if (this.props.multiline && prevProps.value !== this.props.value) {
      this.resize()
    }
  }

  onFocus = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { onFocus } = this.props

    this.setState({
      focused: true,
    })

    if (onFocus) {
      onFocus(event)
    }
  }

  onBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { onBlur } = this.props

    this.setState({
      focused: false,
    })

    if (onBlur) {
      onBlur(event)
    }
  }

  focus = () => {
    this.input.current?.focus()
  }

  onClick = (
    event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement, MouseEvent>,
  ) => {
    const { onClick } = this.props
    event.stopPropagation()

    if (onClick) {
      onClick(event)
    }
  }

  resize = () => {
    // set height to auto to get the scrollHeight
    // then explicitly set the height
    this.setState({ multilineHeight: 'auto' }, () => {
      // keep at auto (1 row) if there is no content/value
      if (!this.props.value) return
      this.setState({
        multilineHeight: `${this.input.current?.scrollHeight}px`,
      })
    })
  }

  onMultilineChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { onChange } = this.props

    this.resize()

    if (onChange) {
      onChange(event)
    }
  }

  onMultilineKeydown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const { onKeyDown } = this.props

    // if "enter" then submit the react
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      event.currentTarget.form?.requestSubmit()
    }

    if (onKeyDown) {
      onKeyDown(event)
    }
  }

  render() {
    const {
      append,
      className,
      disabled,
      error,
      maxlength,
      multiline,
      name,
      prepend,
      success,
      touched,
      value,
      id,
      size,
      inputRef,

      onChange,
      onKeyDown,

      ...props
    } = this.props

    const { focused, multilineHeight } = this.state

    const state = getState({ error, success, touched })
    const classes = cn({
      'mc-form-input': true,
      'mc-form-element': true,
      'mc-form-element--disabled': disabled,
      'mc-form-element--focus': focused,
      [`mc-form-element--${state}`]: state,
      [`mc-form-element--${size}`]: size,
      [className ?? '']: className,
    })

    return (
      <div className={classes} onClick={this.focus}>
        {prepend && <div className='mc-form-prepend'>{prepend}</div>}

        {multiline ? (
          <textarea
            id={id ?? name}
            name={name}
            type='text'
            value={value}
            disabled={disabled}
            className='mc-form-element__element'
            {...props}
            ref={this.input as React.RefObject<HTMLTextAreaElement>}
            onClick={this.onClick}
            onChange={this.onMultilineChange}
            onKeyDown={this.onMultilineKeydown}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            aria-invalid={!!error}
            rows={1}
            style={{
              height: multilineHeight,
              overflow: 'hidden',
              width: '100%',
              resize: 'none',
            }}
          />
        ) : (
          <input
            id={id ?? name}
            name={name}
            type='text'
            value={value}
            disabled={disabled}
            className='mc-form-element__element'
            {...props}
            ref={this.input as React.RefObject<HTMLInputElement>}
            onClick={this.onClick}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            aria-invalid={!!error}
          />
        )}

        {append && <div className='mc-form-append'>{append}</div>}
      </div>
    )
  }
}
