import React, { useRef } from 'react'
import { useTrackingContext } from '@mc/tracking-context'
import {
  TRACK_CONTENT_EVENTS,
  TRACK_PLAYBACK_EVENTS,
  TRACK_PROPS,
  trackMedia,
} from '@mc/track-event'
import { useCallOnChange } from '@mc/hooks'

import { useVideo } from '../VideoContext'
import { VideoEventHandlers } from '..'

type MediaPlaybackEventName =
  (typeof TRACK_PLAYBACK_EVENTS)[keyof typeof TRACK_PLAYBACK_EVENTS]

/**
 * Implements the Media Analytics API when the video state changes
 * Wrap in a `TrackingContext.Provider` to provide the `content_id`, `content_type`, and `content_title` params
 *
 * @see https://masterclass-dev.atlassian.net/l/cp/F1Vqhewy
 */
export const VideoMediaAnalytics = () => {
  const {
    autoPlayed,
    duration,
    fullscreen,
    qualityActual,
    qualityUserSelection,
    speed,
    time,
    volume,
    track,
    tracks,
    lastSeekStartPosition,
  } = useVideo()

  const params = useTrackingContext()
  const previouslyPlayed = useRef(false)

  const cc = tracks[track]?.srcLang ?? 'off'

  const trackEvent = (
    eventName: MediaPlaybackEventName,
    additionalParams: Record<string, string | number | boolean> = {},
  ) => {
    const derivedProps = {
      ...params,
      [TRACK_PROPS.AUTOPLAY]: autoPlayed,
      [TRACK_PROPS.AUTO_QUALITY]: qualityUserSelection.name === 'Auto',
      [TRACK_PROPS.POSITION]: time,
      [TRACK_PROPS.TOTAL_LENGTH]: duration,
      [TRACK_PROPS.SOUND]: (volume ?? 0) * 100,
      [TRACK_PROPS.SPEED]: speed,
      [TRACK_PROPS.QUALITY]: qualityActual?.name,
      [TRACK_PROPS.FULL_SCREEN]: fullscreen ?? false,
      [TRACK_PROPS.PLAYBACK_LOCATION]: 'local',
      [TRACK_PROPS.PICTURE_IN_PICTURE]: false,
      [TRACK_PROPS.CLOSED_CAPTION]: cc,
      [TRACK_PROPS.PLAYER]: 'video',
      [TRACK_PROPS.APP_BACKGROUNDED]: false,
      ...additionalParams,
    } as const

    trackMedia(eventName, derivedProps)
  }

  useCallOnChange(
    (prev) =>
      trackEvent(TRACK_PLAYBACK_EVENTS.SETTINGS_TOGGLED, {
        toggled_setting: 'sound',
        // multiply up from 10x to 100x of original value
        toggled_from: prev * 10,
      }),
    // only fire at intervals of 10 to reduce quantity
    Math.round(volume * 10),
  )

  useCallOnChange(
    (prev) =>
      trackEvent(TRACK_PLAYBACK_EVENTS.SETTINGS_TOGGLED, {
        toggled_setting: 'speed',
        toggled_from: prev,
      }),
    speed,
  )

  useCallOnChange(
    (prev) =>
      trackEvent(TRACK_PLAYBACK_EVENTS.SETTINGS_TOGGLED, {
        toggled_setting: 'full_screen',
        toggled_from: prev,
      }),
    fullscreen,
  )

  useCallOnChange(
    (prev) =>
      trackEvent(TRACK_PLAYBACK_EVENTS.SETTINGS_TOGGLED, {
        toggled_setting: 'cc',
        toggled_from: prev,
      }),
    cc,
  )

  useCallOnChange(
    (prev) =>
      trackEvent(TRACK_PLAYBACK_EVENTS.SETTINGS_TOGGLED, {
        toggled_setting: 'quality',
        toggled_from: prev?.name ?? '',
      }),
    qualityActual,
  )

  /**
   * Media Content Events
   * https://masterclass-dev.atlassian.net/wiki/spaces/DATA/pages/2232680459/Media+Analytics+Spec#MediaAnalyticsSpec-MediaContent
   */
  const trackContent = (eventName: string) => {
    trackMedia(eventName, {
      ...params,
      [TRACK_PROPS.POSITION]: time,
      [TRACK_PROPS.TOTAL_LENGTH]: duration,
    })
  }

  return (
    <VideoEventHandlers
      onPlay={() => {
        if (previouslyPlayed.current) {
          trackEvent(TRACK_PLAYBACK_EVENTS.RESUMED)
        } else {
          trackContent(TRACK_CONTENT_EVENTS.STARTED)

          trackEvent(TRACK_PLAYBACK_EVENTS.STARTED)
          previouslyPlayed.current = true
        }
      }}
      onPause={() => trackEvent(TRACK_PLAYBACK_EVENTS.PAUSED)}
      onEnded={() => {
        previouslyPlayed.current = false
        trackEvent(TRACK_PLAYBACK_EVENTS.COMPLETED)
      }}
      onError={() => trackEvent(TRACK_PLAYBACK_EVENTS.INTERRUPTED)}
      onSeeked={() => {
        trackEvent(TRACK_PLAYBACK_EVENTS.SEEK_COMPLETED, {
          seek_from_position: lastSeekStartPosition,
        })
      }}
      onCompleted={() => trackContent(TRACK_CONTENT_EVENTS.COMPLETED)}
      onTimeUpdate={({
        time: currentTime,
        contentPlayingStartTime,
        seeking,
      }) => {
        const timeSinceStart = currentTime - contentPlayingStartTime
        if (timeSinceStart > 0 && timeSinceStart % 10 === 0 && !seeking) {
          trackContent(TRACK_CONTENT_EVENTS.PLAYING)
        }
      }}
    />
  )
}
