/* istanbul ignore file */
import * as React from 'react'
import type { SVGProps } from 'react'
import PropTypes from 'prop-types'

interface Props extends SVGProps<SVGSVGElement> {
  srText?: string
  size?: 'sm' | 'md' | 'lg' | 'scale-8' | 'scale-10' | number
  alt?: string
}
function IconFullScreenEnterDefault({
  srText,
  size = 'md',
  className = '',
  ...props
}: Props) {
  const newClassName = `mc-icon mc-icon--${size} ${className}`
  return (
    <>
      {!!srText && <span className='mc-sr-only'>{srText}</span>}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={25}
        fill='none'
        viewBox='0 0 24 24'
        aria-hidden='true'
        role='img'
        className={newClassName}
        {...props}
      >
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M3 14.5a1 1 0 0 1 1 1v3h5a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1M2 5.5a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H4v3a1 1 0 1 1-2 0zM14 5.5a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0v-3h-5a1 1 0 0 1-1-1M21 14.5a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-6a1 1 0 0 1 0-2h5v-3a1 1 0 0 1 1-1'
          clipRule='evenodd'
        />
      </svg>
    </>
  )
}
IconFullScreenEnterDefault.propTypes = {
  srText: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
} as PropTypes.ValidationMap<Props>
export default IconFullScreenEnterDefault
