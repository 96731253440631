import { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import Hls from 'hls.js'

import { MediaService, MediaMetadata } from '@mc/edge-api'

import { Source, Track } from '.'
import { useDeterminePlaybackEngine } from './useDeterminePlaybackEngine'

const HLS_TYPES = ['application/x-mpegURL']
const MP4_TYPES = ['video/mp4', 'application/dash+xml']

type VideoSource = {
  poster: string
  sources: Source[]
  tracks: Track[]
}

interface LoadedVideo {
  tracks: Track[]
  poster: string
  source: Source | null
  hls: Hls | null
  decoder: 'hls.js' | 'native-hls' | 'non-hls'
}

/**
 * Loads the video by the given `mediaUuid` or `explicitSource` to the element in the given `videoRef`
 * The `videoRef`'s `loadedmetadata` event will fire when the duration and dimensions are loaded
 */
export const useLoadVideoById = ({
  mediaUuid,
  explicitSource,
  videoRef,
}: {
  mediaUuid: string | undefined
  explicitSource: Source | null | undefined
  videoRef: React.RefObject<HTMLVideoElement>
}): LoadedVideo => {
  const [hls, setHls] = useState<Hls | null>(null)

  const { data: edgeData } = useQuery({
    queryKey: ['edge-media-api', mediaUuid],
    queryFn: () => MediaService.getMediaMetadata({ uuid: mediaUuid as string }),
    enabled: !!mediaUuid && !explicitSource,
    select: presentMediaApiResponse,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  })

  const { tracks, poster, sources } = edgeData ?? {
    tracks: [],
    sources: [],
    poster: '',
    source: null,
  }

  const { source, decoder } = useDetermineSource({
    videoRef,
    sources,
    explicitSource,
  })

  useEffect(() => {
    const video = videoRef.current

    if (!video || !source?.src) return () => {}

    if (decoder !== 'hls.js') {
      video.src = source.src
      video.load()

      return () => {
        video.removeAttribute('src')
        video.load()
      }
    } else {
      const hlsInstance = new Hls({
        renderTextTracksNatively: false,
        capLevelToPlayerSize: true,
        capLevelOnFPSDrop: true,
        backBufferLength: 60,
      })

      setHls(hlsInstance)

      return initializeHls(hlsInstance, source.src, video)
    }
  }, [videoRef, source?.src, decoder])

  return { tracks, poster, decoder, source: source || null, hls }
}

const presentMediaApiResponse = ({
  textTracks = [],
  sources = [],
  poster = '',
}: MediaMetadata): VideoSource => ({
  tracks: textTracks.map(({ src, srclang, kind, label }) => ({
    src,
    srcLang: srclang,
    label,
    kind,
  })) as Track[],
  sources: sources.filter(({ src, type }) => src && type) as Source[],
  poster,
})

const findSourceByType = (sources: Source[], validTypes: string[]) =>
  sources.find(({ type }) => validTypes.some((t) => type.includes(t)))

const useDetermineSource = ({
  videoRef,
  sources,
  explicitSource,
}: {
  videoRef: React.RefObject<HTMLVideoElement>
  sources: Source[]
  explicitSource: Source | null | undefined
}) => {
  const engine = useDeterminePlaybackEngine(videoRef.current)

  const desiredType = engine === 'non-hls' ? MP4_TYPES : HLS_TYPES
  const loadedSource = useMemo(
    () => findSourceByType(sources, desiredType),
    [desiredType, sources],
  )

  if (explicitSource) {
    return {
      source: explicitSource,
      decoder: findSourceByType([explicitSource], HLS_TYPES)
        ? engine
        : 'non-hls',
    }
  }

  return { source: loadedSource, decoder: engine }
}

const initializeHls = (hls: Hls, source: string, video: HTMLVideoElement) => {
  const handleMediaAttached = () => {
    hls.loadSource(source)
  }

  // eslint-disable-next-line no-console
  hls.on(Hls.Events.ERROR, console.error)
  hls.once(Hls.Events.MEDIA_ATTACHED, handleMediaAttached)

  hls.attachMedia(video)
  return () => {
    // eslint-disable-next-line no-console
    hls.off(Hls.Events.ERROR, console.error)
    hls.off(Hls.Events.MEDIA_ATTACHED, handleMediaAttached)
    hls.detachMedia()
    hls.destroy()
  }
}
