import { useCallOnChange, useCallOnEachValue } from '@mc/hooks'
import { VideoContextValue, useVideo } from './VideoContext'

const COMPLETION_THRESHOLD = 0.95

interface Props {
  onPlay?: (ctx: VideoContextValue) => void
  onPlaying?: (ctx: VideoContextValue) => void
  onPause?: (ctx: VideoContextValue) => void
  /**
   * When current time reaches 95% of the duration
   */
  onCompleted?: (ctx: VideoContextValue) => void
  /**
   * When current time reaches 100% of the duration
   */
  onEnded?: (ctx: VideoContextValue) => void
  onError?: (ctx: VideoContextValue) => void
  onTimeUpdate?: (ctx: VideoContextValue) => void
  onProgress?: (ctx: VideoContextValue) => void
  onDurationChange?: (ctx: VideoContextValue) => void
  onVolumeChange?: (ctx: VideoContextValue) => void
  onReady?: (ctx: VideoContextValue) => void
  onSeeking?: (ctx: VideoContextValue) => void
  onSeeked?: (ctx: VideoContextValue) => void
  onRateChange?: (ctx: VideoContextValue) => void
  onWaiting?: (ctx: VideoContextValue) => void
}

export const VideoEventHandlers = (props: Props) => {
  const videoState = useVideo()

  useCallOnChange((_, curr) => {
    if (curr) props.onWaiting?.(videoState)
    else props.onPlaying?.(videoState)
  }, videoState.waiting)

  useCallOnEachValue((_, curr) => {
    switch (curr) {
      case 'playing': {
        props.onPlay?.(videoState)
        break
      }
      case 'paused': {
        props.onPause?.(videoState)
        break
      }
      case 'ended': {
        props.onEnded?.(videoState)
        break
      }
      default:
        break
    }
  }, videoState.state)

  useCallOnEachValue((_, curr) => {
    if (curr === 'ready') {
      props.onReady?.(videoState)
    }
  }, videoState.readyState)

  useCallOnChange((e) => {
    if (e) props.onError?.(videoState)
  }, videoState.error)

  useCallOnChange(() => props.onTimeUpdate?.(videoState), videoState.time)

  const completed =
    videoState.time >= videoState.duration * COMPLETION_THRESHOLD &&
    videoState.duration > 0
  useCallOnChange(() => {
    if (completed) {
      props.onCompleted?.(videoState)
    }
  }, completed)
  useCallOnEachValue(
    () => props.onVolumeChange?.(videoState),
    videoState.volume,
  )
  useCallOnEachValue(
    () => props.onDurationChange?.(videoState),
    videoState.duration,
  )
  useCallOnEachValue(
    () => props.onRateChange?.(videoState),
    videoState.qualityActual?.bitrate,
  )
  useCallOnEachValue(() => props.onProgress?.(videoState), videoState.buffer)
  useCallOnChange(
    (_, nowSeeking) =>
      nowSeeking ? props.onSeeking?.(videoState) : props.onSeeked?.(videoState),
    videoState.seeking,
  )

  return null
}
