import React from 'react'
import { VideoEventHandlers } from '.'

/**
 * HTMLVideoElement's native `loop` implementation doesn't fire onEnded,
 * so we built our own
 */
export const VideoLoop = () => (
  <VideoEventHandlers onEnded={({ restart }) => restart()} />
)
