import React, { useEffect, useRef } from 'react'
import { isEqual } from 'lodash'
import { useVideo } from '..'

/**
 * A basic segment is a window of time between `startTime` and `endTime`.
 */
export interface Segment {
  startTime: number
  endTime: number
}

export interface VideoSegmentProps<TSegment extends Segment> {
  /**
   * Segments (subsections) of the video
   */
  segments: TSegment[]
  /**
   * Called when the video's current time enters the window of a new segment
   * @param seg The segment that was entered
   */
  onSegmentEnter?: (seg: TSegment) => void
  /**
   * Called when the video's current time exits the window of a segment
   * @param seg The segment that was exited
   */
  onSegmentExit?: (seg: TSegment) => void
}

const CUEPOINT_TIME_OFFSET = 2

/**
 * Displays and permits interaction with segments, subsections of the video
 * Should be rendered as a child of VideoScrubber
 */
export const VideoSegments = <TSegment extends Segment>({
  segments,
  onSegmentEnter,
  onSegmentExit,
}: VideoSegmentProps<TSegment>) => {
  const { duration, time } = useVideo()
  const prevSegment = useRef<TSegment | null>(null)

  useEffect(() => {
    const currentSegment = segments.find(
      (seg) => seg.startTime <= time && seg.endTime >= time,
    )

    if (!isEqual(currentSegment, prevSegment.current)) {
      if (currentSegment) {
        onSegmentEnter?.(currentSegment)
      }

      if (prevSegment.current) {
        onSegmentExit?.(prevSegment.current)
      }
    }

    prevSegment.current = currentSegment ?? null
  }, [segments, time, onSegmentEnter, onSegmentExit])

  return (
    <div data-testid='video-segments'>
      {segments.map(({ startTime }) => (
        <div
          key={startTime}
          className='mc-opacity--muted position-absolute'
          style={{
            left: `${((startTime + CUEPOINT_TIME_OFFSET) / duration) * 100}%`,
            width: `var(--mc-scale-2)`,
            height: 'var(--mc-scale-1)',
            backgroundColor: '#000',
            pointerEvents: 'none',
          }}
        />
      ))}
    </div>
  )
}
