import { getAccountId } from '@mc/user-info'
import trackEvent from '../../core/interactors/trackEvent'
import { TRACK_PROPS } from './trackMediaConstants'

export const TRACK_PRODUCT_TYPES = {
  DEFAULT: 'MasterClass',
  MASTER_CAMP: 'MasterCamp',
}

export const TRACK_CONTENT_TYPES = {
  COURSE_CHAPTER: 'Course Chapter',
  COURSE_PREVIEW: 'Course Preview',
  COURSE_SAMPLE: 'Course Sample',
  COURSE_TRAILER: 'Course Trailer',
  CDP_HERO: 'CDP Hero Auto Play',

  CAMP_MARKETING: 'Camp Marketing',
  CAMP_TRAILER: 'Camp Trailer',
  INSTRUCTOR_INTERACTION: 'Instructor Interaction',

  PACKAGE_CHAPTER: 'Package Chapter',

  SERIES_EPISODE: 'Series',
  EPISODE_GEM: 'Episode Gem',

  GEM_PREVIEW: 'Gem Preview',

  SHORTFORM: 'Shortform',
  QUICKLIST: 'Quicklist',
  MARKETING: 'Marketing',

  TASK: 'Task',
  POST_PW_MARKETING: 'Post PW Marketing',

  ONBOARDING: 'Onboarding',
  EMBED: 'Video Embed',

  FLW: 'Frontline Worker',

  ON_CALL_DEMO: 'On Call Demo', // for com-1208_v1 - delete if exp is cleaned up
} as const

type TrackContentKeys = keyof typeof TRACK_CONTENT_TYPES
export type TrackContentType = (typeof TRACK_CONTENT_TYPES)[TrackContentKeys]

export const TRACK_COURSE_TRAILER_TITLE = 'Course Trailer'
export const TRACK_COURSE_PREVIEW_TITLE = 'Course Preview'

export const TRACK_PLAYBACK_EVENTS = {
  STARTED: 'Media Playback Started',
  PAUSED: 'Media Playback Paused',
  INTERRUPTED: 'Media Playback Interrupted',
  SEEK_COMPLETED: 'Media Playback Seek Completed',
  RESUMED: 'Media Playback Resumed',
  COMPLETED: 'Media Playback Completed',
  SKIPPED: 'Media Playback Skipped',
  SETTINGS_TOGGLED: 'Media Playback Settings Toggled',
} as const

export const TRACK_CONTENT_EVENTS = {
  STARTED: 'Media Content Started',
  PLAYING: 'Media Content Playing',
  COMPLETED: 'Media Content Completed',
} as const

const validateSessionIds = (IDs: Record<string, unknown>) => {
  const { PLAYER_SESSION_ID, MEDIA_SESSION_ID } = TRACK_PROPS

  if (!IDs[PLAYER_SESSION_ID]) {
    throw new Error(`Missing ${PLAYER_SESSION_ID}`)
  }
  if (!IDs[MEDIA_SESSION_ID]) {
    throw new Error(`Missing ${MEDIA_SESSION_ID}`)
  }
}

const trackMedia = (
  eventName: string,
  sessionIDs = {},
  metadata: Record<string, string | unknown> = {},
) => {
  validateSessionIds(sessionIDs)

  const { USER_ID } = TRACK_PROPS
  trackEvent(eventName, {
    ...sessionIDs,
    ...metadata,
    [USER_ID]: metadata.user_id || getAccountId(),
  })
}

export default trackMedia
