import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

const decToPerc = (dec: number) => `${dec * 100}%`
interface SliderProps {
  buffer?: number
  color?: 'primary' | 'light'
  label?: string
  step?: number
  value: number
  valueText?: (value: number) => string
  onChange: (newValue: number, dragging: boolean) => void
  onChangeEnd?: (newValue: number) => void
  /**
   * For rendering an overlay between the track and the handle
   */
  children?: React.ReactNode
  transitionDuration?: number
}

const STEP = 0.1

const Slider = ({
  buffer = 0,
  color = 'primary',
  label = 'Slider',
  step = STEP,
  value = 0,
  transitionDuration = 300,
  valueText = decToPerc,
  onChange,
  onChangeEnd,
  children,
}: SliderProps) => {
  const [dragging, setDragging] = useState(false)
  const lastValue = useRef<number>(value)

  const handleScrubberInteraction =
    (scrubber: HTMLDivElement) =>
    (event: MouseEvent | TouchEvent, draggingOverride = dragging) => {
      const { left: scrubberLeft, width: scrubberWidth } =
        scrubber.getBoundingClientRect()

      let eventLeft: number
      if ('clientX' in event) {
        eventLeft = event.clientX
      } else {
        eventLeft = event.touches[0].clientX
      }

      const left = Math.min(
        Math.max(0, eventLeft - scrubberLeft),
        scrubberWidth,
      )
      const newValue = left / scrubberWidth
      lastValue.current = newValue

      onChange(newValue, draggingOverride)
    }

  const handleScrubberTouchStart: React.TouchEventHandler<HTMLDivElement> = (
    event,
  ) => {
    const listener = handleScrubberInteraction(event.currentTarget)

    listener(event.nativeEvent, true)

    setDragging(true)

    window.addEventListener('touchmove', listener)
    window.addEventListener(
      'touchend',
      () => {
        window.removeEventListener('touchmove', listener)
        onChangeEnd?.(lastValue.current)
        setDragging(false)
      },
      { once: true },
    )
  }

  const handleScrubberMouseDown: React.MouseEventHandler<HTMLDivElement> = (
    event,
  ) => {
    const listener = handleScrubberInteraction(event.currentTarget)

    listener(event.nativeEvent, true)

    setDragging(true)

    window.addEventListener('mousemove', listener)
    window.addEventListener(
      'mouseup',
      () => {
        window.removeEventListener('mousemove', listener)
        onChangeEnd?.(lastValue.current)
        setDragging(false)
      },
      { once: true },
    )
  }

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (event: {
    key: string
    currentTarget: HTMLDivElement
    shiftKey: boolean
  }) => {
    const { key, shiftKey } = event

    switch (key) {
      case 'ArrowLeft':
        onChange(value - (shiftKey ? step * 5 : step), false)
        break
      case 'ArrowRight':
        onChange(value + (shiftKey ? step * 5 : step), false)
        break
      default:
        break
    }
  }

  return (
    <div
      className={`mc-slider mc-slider--color-${color}`}
      role='slider'
      tabIndex={0}
      aria-label={label}
      aria-valuenow={value}
      aria-valuemin={0}
      aria-valuemax={100}
      aria-valuetext={valueText(value)}
      onMouseDown={handleScrubberMouseDown}
      onTouchStart={handleScrubberTouchStart}
      onKeyDown={handleKeyDown}
    >
      <div className='mc-slider__bar'>
        <div
          className='mc-slider__buffer'
          style={{ width: decToPerc(buffer) }}
        />
        <div
          className='mc-slider__fill'
          style={{
            width: decToPerc(value),
            transitionDuration: `${dragging ? 0 : transitionDuration}ms`,
          }}
        />
        {children}
        <div className='mc-slider__handle-container'>
          <div
            className='mc-slider__handle'
            style={{
              left: decToPerc(value),
              transitionDuration: `${dragging ? 0 : transitionDuration}ms`,
            }}
          />
        </div>
      </div>
    </div>
  )
}

Slider.propTypes = {
  value: PropTypes.number.isRequired,
  buffer: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

export default Slider
