import React from 'react'
import { defer } from 'lodash'
import { IconHeadphonesDefault } from '@mc/design-system'
import { useTranslation } from '@mc/i18n'
import { ControlDropdown } from './ControlDropdown'
import { useVideo } from '..'
import { useVideoSetting } from '../userSettings'

/**
 * Displays an interactive Audio Tracks button & menu.
 *
 * Backed by localStorage.
 */
export const VideoAudioTracks = () => {
  const { audioTrack, audioTracks, setAudioTrack } = useVideo()
  const { t } = useTranslation('@mc/video')

  const [savedAudioTrack, setSavedAudioTrack] =
    useVideoSetting('video_audio_track')

  if (audioTracks.length < 2) {
    return null
  }

  const savedTrackIndex = audioTracks.findIndex(
    (value) => value.name === savedAudioTrack,
  )

  if (savedTrackIndex > -1 && savedTrackIndex !== audioTrack) {
    defer(() => setAudioTrack(savedTrackIndex))
  }

  const handleClick = (newIndex: number) => {
    setSavedAudioTrack(audioTracks[newIndex].name)
    setAudioTrack(newIndex)
  }

  return (
    <ControlDropdown
      title={t('video.audioTracks.title')}
      icon={<IconHeadphonesDefault />}
      selectedValue={audioTrack}
      options={audioTracks.map((track, i) => ({ label: track.name, value: i }))}
      onClick={handleClick}
    />
  )
}
