import validate from 'validate.js'

import { i18nInstance } from '../i18n/i18nInstance'
import { EMAIL_CAPTURE_I18N } from '../constants'
import { EmailCaptureFormValues } from '../types'

const t = i18nInstance.getFixedT(null, EMAIL_CAPTURE_I18N)

const BASE_CONSTRAINTS = {
  email: {
    presence: {
      message: t('email.required'),
    },
    email: {
      message: t('email.invalid'),
    },
  },
}

const GDPR_CONSENT_REQUIRED_CONSTRAINTS = {
  gdprConsent: {
    presence: {
      message: t('gdprCheckbox.required'),
    },
    inclusion: {
      within: [true],
      message: t('gdprCheckbox.required'),
    },
  },
}

export const getEmailFormValidator =
  ({ isGdprConsentRequired }: { isGdprConsentRequired?: boolean }) =>
  (values: EmailCaptureFormValues) =>
    validate(
      values,
      {
        ...BASE_CONSTRAINTS,
        ...(isGdprConsentRequired ? GDPR_CONSENT_REQUIRED_CONSTRAINTS : {}),
      },
      { fullMessages: false },
    )
