import { useEffect } from 'react'

let videos: EventTarget

if (typeof window !== 'undefined') {
  videos = new EventTarget()
}

export const registerActivePlayer = (player: HTMLVideoElement) =>
  videos.dispatchEvent(new CustomEvent('play', { detail: { video: player } }))

/**
 * Ensures that only a single video player can be active on the screen at any given time
 *
 * When a new player begins playing, all other players on the screen are paused.
 * The `active` field in the VideoContext is `true` for the active player alone
 */
export const useVideoSingleton = ({
  videoRef,
  setActive,
  disable,
}: {
  videoRef: React.RefObject<HTMLVideoElement>
  setActive: (active: boolean) => void
  disable?: boolean
}) =>
  useEffect(() => {
    if (!videoRef.current) return () => {}
    if (disable) return () => {}

    const handlePlay = (event: CustomEvent) => {
      if (event.detail.video === videoRef.current) {
        setActive(true)
      } else {
        videoRef.current?.pause()
        setActive(false)
      }
    }

    videos.addEventListener('play', handlePlay as EventListener)

    return () => {
      videos.removeEventListener('play', handlePlay as EventListener)
    }
  }, [disable, setActive, videoRef])
