import { registerI18nResources } from '@mc/i18n'
import enUS from './locales/en-US.json'

const i18nInstance = registerI18nResources({
  namespace: '@mc/video',
  resources: {
    'en-US': enUS, // always load 'en' for fallback
  },
})

export default i18nInstance
