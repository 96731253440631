/* eslint-disable no-nested-ternary */
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../../../node_modules/mux-embed/dist/types/mux-embed.d.ts"/>
import React, { useEffect, useRef } from 'react'
import Hls, { ErrorData } from 'hls.js'
import mux from 'mux-embed'
import Cookies from 'js-cookie'

import { getEnvVar } from '@mc/client-env'
import { TRACK_PROPS, getAnonymousId } from '@mc/track-event'
import { useTrackingContext } from '@mc/tracking-context'
import {
  isLoggedIn,
  getAccountProperties,
  getCurrentProfile,
  getCurrentProfileId,
  hasActiveAnnualMembership,
} from '@mc/user-info'
import { useBucket } from '@mc/experiments-react'

import { useVideo } from '.'

export const MUX_MANUAL_ERRORS = 'infra_video_manual_mux_errors'

const REQUEST_FAILED_DETAILS = [
  Hls.ErrorDetails.MANIFEST_LOAD_ERROR,
  Hls.ErrorDetails.MANIFEST_LOAD_TIMEOUT,
  Hls.ErrorDetails.FRAG_LOAD_ERROR,
  Hls.ErrorDetails.FRAG_LOAD_TIMEOUT,
  Hls.ErrorDetails.LEVEL_LOAD_ERROR,
  Hls.ErrorDetails.LEVEL_LOAD_TIMEOUT,
  Hls.ErrorDetails.AUDIO_TRACK_LOAD_ERROR,
  Hls.ErrorDetails.AUDIO_TRACK_LOAD_TIMEOUT,
  Hls.ErrorDetails.SUBTITLE_LOAD_ERROR,
  Hls.ErrorDetails.SUBTITLE_TRACK_LOAD_TIMEOUT,
  Hls.ErrorDetails.KEY_LOAD_ERROR,
  Hls.ErrorDetails.KEY_LOAD_TIMEOUT,
]

/**
 * Implements & manages the Mux Embed contract
 */
export const VideoQoS = ({
  videoRef,
  hls,
  videoId,
  mediaUuid,
}: {
  videoRef: React.RefObject<HTMLVideoElement>
  hls: Hls | null
  videoId?: string
  mediaUuid?: string
}) => {
  const {
    autoPlayed,
    duration,
    source,
    decoder,
    fullscreen,
    initTime,
    readyState,
  } = useVideo()
  const {
    [TRACK_PROPS.VISIT_SESSION_ID]: viewSessionId,
    [TRACK_PROPS.PLAYER_SESSION_ID]: playerSessionId,
    [TRACK_PROPS.MEDIA_SESSION_ID]: mediaSessionId,
    [TRACK_PROPS.CONTENT_TYPE]: videoContentType,
    [TRACK_PROPS.CONTENT_ID]: contentId,
    [TRACK_PROPS.CONTENT_TITLE]: videoTitle,
    [TRACK_PROPS.PAGE_TYPE]: pageType,
  } = useTrackingContext()
  const videoSourceType = source?.type

  const manualErrors = useBucket<'manual' | 'automatic' | null>(
    MUX_MANUAL_ERRORS,
  )

  const initialized = useRef<HTMLVideoElement | null>(null)

  const currentVideo = videoRef.current

  useEffect(
    () => () => {
      if (initialized.current) mux.destroyMonitor(initialized.current)
    },
    [],
  )

  useEffect(() => {
    if (manualErrors !== 'manual') {
      return () => {}
    }

    if (hls && currentVideo) {
      hls.on(Hls.Events.ERROR, (_, data) => {
        const { type, details, response, fatal, frag } = data
        const url = frag?.url || data.url || ''

        if (REQUEST_FAILED_DETAILS.includes(details)) {
          mux.emit(currentVideo, 'requestfailed', {
            request_error: details,
            request_url: url,
            request_hostname: extractHostname(url),
            request_id: undefined, // TODO: extract from headers?
            request_type:
              details === Hls.ErrorDetails.FRAG_LOAD_ERROR ||
              details === Hls.ErrorDetails.FRAG_LOAD_TIMEOUT
                ? 'media'
                : details === Hls.ErrorDetails.AUDIO_TRACK_LOAD_ERROR ||
                    details === Hls.ErrorDetails.AUDIO_TRACK_LOAD_TIMEOUT
                  ? 'audio'
                  : details === Hls.ErrorDetails.SUBTITLE_LOAD_ERROR ||
                      details === Hls.ErrorDetails.SUBTITLE_TRACK_LOAD_TIMEOUT
                    ? 'subtitle'
                    : details === Hls.ErrorDetails.KEY_LOAD_ERROR ||
                        details === Hls.ErrorDetails.KEY_LOAD_TIMEOUT
                      ? 'encryption'
                      : 'manifest',
            request_error_code: response?.code as number,
            request_error_text: response?.text as string,
          })
        }

        if (fatal) {
          mux.emit(currentVideo, 'error', {
            player_error_code: type as unknown as number,
            player_error_message: details,
            player_error_context: buildContext(data),
          })
        }
      })

      return () => {
        hls.off(Hls.Events.ERROR)
      }
    } else {
      const errorListener = (e: ErrorEvent) => {
        const error = e.error || (e.target as HTMLVideoElement | null)?.error
        if (!error) {
          // https://github.com/muxinc/elements/blob/5a89efb3810c498dc27ddb2520c34d6371c8c43b/packages/playback-core/src/index.ts#L887
          // eslint-disable-next-line no-console
          console.warn('received an error event without an error, ignoring', e)
          return
        }

        // If native HLS is used on Safari, M3U8 response errors cause media src not supported errors.
        // If the response returns an error code, fix the MediaError.code and get detailed error logs.
        const status = error.data?.response?.code
        if (status >= 400 && status < 500) {
          error.code = MediaError.MEDIA_ERR_NETWORK
          error.data = { response: { code: status } }
        }

        mux.emit(e.currentTarget as HTMLVideoElement, 'error', {
          player_error_code: error.code,
          player_error_message: error.message,
          player_error_context: error.data?.response
            ? `response: ${error.data.response.code}`
            : undefined,
        })
      }
      currentVideo?.addEventListener('error', errorListener)

      return () => {
        currentVideo?.removeEventListener('error', errorListener)
      }
    }
  }, [hls, currentVideo, manualErrors])

  // this only happens once, depends on these conditions, and doesn't need useEffect
  // important to call mux.monitor _after_ source is loaded, or Mux will not load source metadata correctly
  if (
    readyState !== 'loading' &&
    currentVideo &&
    initialized.current !== currentVideo
  ) {
    const DEBUG =
      Cookies.get('MUX_DATA_DEBUG') === 'true' ||
      getEnvVar('MUX_DATA_DEBUG') === 'true'
    if (initialized.current) {
      mux.destroyMonitor(initialized.current)
    }

    if (!videoContentType && getEnvVar('NODE_ENV') === 'development') {
      // eslint-disable-next-line no-console
      console.warn(
        'WARNING: No content type specified for Mux integration',
        videoContentType,
      )
    }

    if (!pageType && getEnvVar('NODE_ENV') === 'development') {
      // eslint-disable-next-line no-console
      console.warn(
        'WARNING: No page type specified for Mux integration',
        pageType,
      )
    }

    const hlsParams = hls
      ? {
          hlsjs: hls,
          Hls,
        }
      : {}

    const anonymousId = getAnonymousId() ?? undefined
    mux.monitor(currentVideo, {
      debug: DEBUG,
      automaticErrorTracking: manualErrors !== 'manual',
      data: {
        player_name: 'Web Player',
        player_init_time: initTime,
        env_key: (getEnvVar('MUX_DATA_ENV_KEY') as string) ?? 'unknown',

        player_autoplay_on: autoPlayed,
        video_id: (mediaUuid ?? videoId ?? contentId)?.toString(),
        video_title: videoTitle,
        video_duration: duration * 1000,
        video_content_type: videoContentType,
        player_software_name: decoder,
        video_stream_type: 'on-demand' as const,
        video_source_mime_type: videoSourceType,
        page_type: pageType,
        used_fullscreen: fullscreen,

        view_session_id: viewSessionId,
        viewer_user_id: anonymousId,

        custom_1: getAccountProperties()?.id?.toString(),
        custom_2: playerSessionId,
        custom_3: mediaSessionId,

        custom_5: anonymousId,
        custom_6: viewSessionId,

        custom_7: isLoggedIn()?.toString(),
        custom_8: hasActiveAnnualMembership()?.toString(),
        custom_9: (isLoggedIn()
          ? getCurrentProfileId() ??
            getCurrentProfile()?.id ??
            getAccountProperties().profileId
          : undefined
        )?.toString(),
      },
      ...hlsParams,
    })

    // ensure initialization only happens once per video el
    initialized.current = currentVideo
  }

  return null
}

const buildContext = (data: ErrorData) => {
  const ctx = []
  if (data.url) {
    ctx.push(`url: ${data.url}`)
  }
  if (data.response) {
    ctx.push(`response: ${data.response.code}, ${data.response.text}`)
  }
  if (data.reason) {
    ctx.push(`failure reason: ${data.reason}`)
  }
  if (data.level) {
    ctx.push(`level: ${data.level}`)
  }
  if (data.parent) {
    ctx.push(`parent stream controller: ${data.parent}`)
  }
  if (data.buffer) {
    ctx.push(`buffer length: ${data.buffer}`)
  }
  if (data.error) {
    ctx.push(`error: ${data.error}`)
  }
  if (data.event) {
    ctx.push(`event: ${data.event}`)
  }
  if (data.err) {
    ctx.push(`error message: ${data.err}`)
  }
  return ctx.join('\n')
}

const extractHostname = (url: string) => new URL(url).hostname
