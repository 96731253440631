import React, { useState, useRef, useEffect, useCallback } from 'react'
import Link from 'next/link'

import {
  Button,
  IconPlayDefault,
  IconPlaybackStopDefault,
} from '@mc/design-system'
import { useTranslation } from '@mc/i18n'

import { usePersonaEvents } from '../../../events'
import AIBadgeFilled from '../../../assets/ai-badge-filled-white.svg'
import Image from '../../../components/Image'

import type { Mentor, ExampleType } from '../../../types/mentor'
import { ROW_TRACKING } from '../utils'
import styles from './MentorCard.module.scss'

const useAudio = (source?: string) => {
  const audioRef = useRef<HTMLAudioElement | null>(null)
  const [isPlaying, setPlaying] = useState(false)
  const [isFirstPlay, setFirstPlay] = useState(true)
  const [progress, setProgress] = useState(0)

  const play = () => {
    if (!isPlaying) {
      if (source) {
        setPlaying(true)
        const audio = new Audio(source)
        audioRef.current = audio

        audio.play()
        audio.ontimeupdate = () => {
          setProgress((audio.currentTime / audio.duration) * 100)
        }
        audio.onended = () => {
          setPlaying(false)
          audio.currentTime = 0
        }
      }
    }
  }

  const stop = useCallback(() => {
    if (isPlaying) {
      setPlaying(false)
      setFirstPlay(false)
      if (audioRef.current) {
        audioRef.current.pause()
        audioRef.current.currentTime = 0
      }
    }
  }, [isPlaying])

  const togglePlay = () => {
    if (isPlaying) {
      stop()
    } else {
      play()
    }
  }

  useEffect(
    () => () => {
      if (isPlaying) {
        stop()
      }
    },
    [stop, isPlaying],
  )

  return {
    isPlaying,
    isFirstPlay,
    progress,
    play,
    stop,
    togglePlay,
  }
}

const Example = ({
  example,
  analytics,
  className = '',
  currentAudio,
  onPlay,
}: {
  example: ExampleType
  analytics: { mentor: string; instructorSlug: string; location: string }
  className?: string
  currentAudio: string | null
  onPlay: (audio: string | null) => void
}) => {
  const { isPlaying, isFirstPlay, progress, togglePlay } = useAudio(
    example.audioSrc,
  )

  useEffect(() => {
    if (currentAudio && currentAudio !== example.caption && isPlaying) {
      togglePlay()
    }
  }, [currentAudio, isPlaying, togglePlay, example.caption])

  const { trackPersonaSignClick } = usePersonaEvents()

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    if (!example.audioSrc) {
      return
    }

    onPlay(isPlaying ? null : example.caption)
    let action = isFirstPlay ? 'play' : 'replay'

    if (isPlaying) {
      action = 'stop'
    }

    togglePlay()
    trackPersonaSignClick({
      location: analytics.location,
      instructorSlug: analytics.instructorSlug,
      cta: example.caption,
      action,
    })
  }

  return (
    <Button
      kind='secondary'
      onClick={handleClick}
      fullWidth
      className={`${styles.suggestion} mc-corners--xl ${className}`}
      style={{ overflow: 'hidden' }}
      disabled={!example.audioSrc}
    >
      {example.audioSrc && (
        <div
          className='position-absolute'
          style={{
            background: 'var(--mc-color-bg-container-tint-medium)',
            top: 0,
            left: 0,
            right: `${100 - progress}%`,
            bottom: 0,
          }}
        />
      )}
      <p
        className={`mc-text--normal mc-text--left mc-text-small d-md-none ${styles.noBlur}`}
        style={{ zIndex: 1 }}
      >
        {example.caption}
      </p>
      <p
        className={`mc-text--normal mc-text--left d-none d-md-block ${styles.noBlur}`}
        style={{ zIndex: 1 }}
      >
        {example.caption}
      </p>
      {example.audioSrc && (
        <>
          {isPlaying ? (
            <IconPlaybackStopDefault
              size='sm'
              className='position-absolute mc-mr-5'
              style={{ right: 0 }}
            />
          ) : (
            <IconPlayDefault
              size='sm'
              className='position-absolute mc-mr-5'
              style={{ right: 0 }}
            />
          )}
        </>
      )}
    </Button>
  )
}

export const MentorCard = ({
  mentor,
  onClick,
  currentAudio,
  onPlay,
  analytics,
}: {
  mentor: Mentor
  onClick: () => void
  currentAudio: string | null
  onPlay: (audio: string | null) => void
  analytics: typeof ROW_TRACKING.yourAiMentors
}) => {
  const { t } = useTranslation('@mc/persona')

  return (
    <>
      <Link href={mentor.redirectUrl || ''} onClick={onClick}>
        <div className='position-relative'>
          <Image {...mentor.image} priority className='mc-corners--lg' />
          <AIBadgeFilled
            className={styles.badge}
            alt={t('common.aiAlt')}
            height={23}
          />
          <div
            className={`${styles.textContainer} mc-text--center mc-p-6 mc-corners--lg`}
          >
            <h3 className='mc-text-h2 mc-text--bold mc-mb-3'>{mentor.title}</h3>
            <p className='mc-text-h6 mc-text-color--medium mc-mb-4'>
              {mentor.keywords.join('  •  ')}
            </p>
            {mentor.examples?.map((example, i) => (
              <Example
                className={
                  i !== (mentor?.examples?.length || 0) - 1 ? 'mc-mb-2' : ''
                }
                key={i}
                example={example}
                analytics={{
                  mentor: mentor.title,
                  instructorSlug: mentor.slug,
                  location: analytics.rowName,
                }}
                currentAudio={currentAudio}
                onPlay={onPlay}
              />
            ))}
          </div>
        </div>
      </Link>
    </>
  )
}
