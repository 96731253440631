import { useCallback, useRef } from 'react'
import { Actions } from './videoReducer'

export const useInteraction = (dispatch: React.Dispatch<Actions>) => {
  const FADE_DELAY = 3000

  const interactingTimeout = useRef<NodeJS.Timeout | null>(null)

  return useCallback(() => {
    dispatch({ type: 'interacted' })

    if (interactingTimeout.current) clearTimeout(interactingTimeout.current)

    interactingTimeout.current = setTimeout(() => {
      dispatch({ type: 'nonInteracted' })
      interactingTimeout.current = null
    }, FADE_DELAY)
  }, [dispatch])
}
