import { useCallback, useState } from 'react'
import { isNull } from 'lodash'
import { getCurrentProfile } from '@mc/user-info'

const VIDEO_MUTED = 'video_muted'
const VIDEO_VOLUME = 'video_volume'
const VIDEO_CAPTIONS = 'video_captions'
const VIDEO_RATE = 'video_rate'
const VIDEO_QUALITY = 'video_quality'
const VIDEO_AUDIO_TRACK = 'video_audio_track'

/**
 * One of the video settings stored in localStorage.
 * @type {VideoSetting}
 */
export type VideoSetting =
  | 'video_muted'
  | 'video_volume'
  | 'video_captions'
  | 'video_rate'
  | 'video_quality'
  | 'video_audio_track'

type VideoSettingValue<T extends VideoSetting> = T extends
  | 'video_captions'
  | 'video_audio_track'
  ? string
  : T extends 'video_muted'
    ? boolean
    : number

export const DEFAULTS: Record<VideoSetting, string | boolean | undefined> = {
  video_captions: 'Off',
  video_audio_track: 'en (Main)',
  video_muted: false,
  video_rate: undefined,
  video_quality: undefined,
  video_volume: undefined,
}

const getItemKey = (key: string) => {
  const { uuid } = getCurrentProfile() ?? {}

  return uuid ? `${key}-${uuid}` : key
}

const getItem = (key: string) =>
  typeof window !== 'undefined'
    ? window.localStorage.getItem(getItemKey(key))
    : null
const setItem = (key: string, value: string) =>
  typeof window !== 'undefined'
    ? window.localStorage.setItem(getItemKey(key), value)
    : null

const parseValue = <TName extends VideoSetting>(
  name: TName,
  value: string | null,
): VideoSettingValue<TName> | null => {
  switch (name) {
    case 'video_rate':
    case 'video_quality':
    case 'video_volume':
      return isNull(value) ? null : (Number(value) as VideoSettingValue<TName>)
    case 'video_muted':
      return isNull(value)
        ? null
        : (Boolean(value && value !== 'false') as VideoSettingValue<TName>)
    default:
      return value as VideoSettingValue<TName> | null
  }
}

/**
 * Uses a video setting saved in local storage.
 *
 * @param {VideoSetting} name The name of the setting
 */
export const useVideoSetting = <TName extends VideoSetting>(name: TName) => {
  const [value, _setValue] = useState<VideoSettingValue<TName> | null>(
    () =>
      (parseValue(name, getItem(name)) as VideoSettingValue<TName>) ??
      DEFAULTS[name],
  )

  const setValue = useCallback(
    (newValue: VideoSettingValue<TName> | null) => {
      setItem(name, newValue?.toString() ?? '')
      _setValue(newValue)
    },
    [name],
  )

  return [value, setValue] as const
}

export const resetUserSettings = () => {
  const settings = [
    VIDEO_MUTED,
    VIDEO_VOLUME,
    VIDEO_CAPTIONS,
    VIDEO_RATE,
    VIDEO_QUALITY,
    VIDEO_AUDIO_TRACK,
  ]
  settings.forEach((setting) =>
    window.localStorage.removeItem(getItemKey(setting)),
  )
}
