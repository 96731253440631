/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react'

import { Trans } from '@mc/i18n'
import {
  AI_TERMS_HREF,
  PRIVACY_POLICY_HREF,
  TERMS_OF_SERVICE_HREF,
  SPEECH_TO_TEXT_NOTICE_HREF,
} from '../../utils/legal'

export const Terms = ({
  className,
  ctaCopy = 'Continue',
}: {
  className?: string
  ctaCopy?: string
}) => (
  <p className={`mc-text-small mc-text-color--medium ${className}`}>
    <Trans i18nKey='auth.common.terms' ns='@mc/persona' values={{ ctaCopy }}>
      <a
        className='mc-text--link'
        href={TERMS_OF_SERVICE_HREF}
        target='_blank'
        rel='noreferrer'
      />
      <a
        className='mc-text--link'
        href={AI_TERMS_HREF}
        target='_blank'
        rel='noreferrer'
      />
      <a
        className='mc-text--link'
        href={PRIVACY_POLICY_HREF}
        target='_blank'
        rel='noreferrer'
      />
      <a
        className='mc-text--link'
        href={SPEECH_TO_TEXT_NOTICE_HREF}
        target='_blank'
        rel='noreferrer'
      />
      <span>{ctaCopy}</span>
    </Trans>
  </p>
)
