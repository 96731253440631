import React from 'react'

export const HDIcon = ({
  className,
  style,
}: {
  className?: string
  style?: React.CSSProperties
}) => (
  <span
    className={`mc-corners--sm mc-bg-gray-400 ${className}`}
    style={{
      top: 0,
      right: 0,
      fontSize: 'var(--mc-scale-2)',
      fontWeight: 700,
      padding: 2,
      lineHeight: 1,
      borderRadius: 2,
      textAlign: 'center',
      ...style,
    }}
  >
    {'HD'}
  </span>
)
