import React, { useState, useEffect } from 'react'
import { number, string } from 'prop-types'
import debounce from 'lodash/debounce'
import StickyControlled from '../StickyControlled'
import { PROP_TYPE_CHILDREN } from '../constants'
import { PROP_TYPE_PLACEMENT } from './constants'

interface StickyProps {
  placement?: string
  offset?: number
  children: React.ReactElement
  className?: string
  showAtPosition?: number
  hideAtPosition?: number
  role?: string
  'aria-label'?: string
}

const Sticky = ({
  placement,
  offset,
  children,
  className,
  showAtPosition = 1,
  hideAtPosition,
  role = 'region',
  'aria-label': ariaLabel,
}: StickyProps) => {
  const [stickyActive, setStickyActive] = useState(false)

  const determinePosition = debounce(() => {
    const scrollDistance = window.pageYOffset

    let display = scrollDistance > showAtPosition

    if (hideAtPosition !== undefined) {
      display =
        scrollDistance >= showAtPosition && scrollDistance < hideAtPosition
    }

    setStickyActive(display)
  }, 50)

  useEffect(() => {
    determinePosition()
  }, [determinePosition])

  useEffect(() => {
    window.addEventListener('scroll', determinePosition)
    window.addEventListener('resize', determinePosition)

    return () => {
      window.removeEventListener('scroll', determinePosition)
      window.removeEventListener('resize', determinePosition)
    }
  }, [determinePosition])

  return (
    <StickyControlled
      show={stickyActive}
      placement={placement}
      offset={offset}
      className={className}
      ariaLabel={ariaLabel}
      role={role}
    >
      {children}
    </StickyControlled>
  )
}

Sticky.propTypes = {
  showAtPosition: number,
  hideAtPosition: number,
  offset: number,
  className: string,
  placement: PROP_TYPE_PLACEMENT,
  children: PROP_TYPE_CHILDREN,
}

export default Sticky
