import { useEffect, useRef } from 'react'
import { isEqual } from 'lodash'

/**
 * Like `useCallOnEachValue`, but does not call for the initial value
 *
 * @param callback
 * @param dependencies
 */
export const useCallOnChange = <T>(
  callback: (prev: T, curr: T) => void,
  dependencies: T,
) => {
  const previousDependencies = useRef<T>(dependencies)

  useEffect(() => {
    if (!isEqual(previousDependencies.current, dependencies)) {
      callback(previousDependencies.current, dependencies)
      previousDependencies.current = dependencies
    }
  }, [callback, dependencies])
}
