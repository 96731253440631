import React, { createContext, useContext, useRef, useEffect } from 'react'

import { useSegmentTracking } from './segmentTrackingContext'

export type TriggerEvent<T> = <Key extends string & keyof T>(
  eventType: Key,
  event: T[Key],
) => void

type Track<T> = <Key extends string & keyof T>(
  eventType: Key,
  event: T[Key],
) => void

// https://stackoverflow.com/questions/50374908/transform-union-type-to-intersection-type
type UnionToIntersection<U> = (
  U extends unknown ? (x: U) => void : never
) extends (x: infer I) => void
  ? I
  : never

type EventParams<T> = Partial<UnionToIntersection<T[keyof T]>>

export type EventHandlers<T, E> = {
  [Key in keyof E]: (
    track: Track<T>,
    event: E[Key],
    params: EventParams<T> | Record<string, never>,
  ) => void
}

export const createTrackingContext = <
  TRACK_EVENTS extends { [key: string]: { [key: string]: string | number } },
  TRIGGER_EVENTS,
>(
  eventHandlers: EventHandlers<TRACK_EVENTS, TRIGGER_EVENTS>,
) => {
  const TrackingContext = createContext<EventParams<TRACK_EVENTS> | null>(null)

  const TrackingProvider = ({
    params,
    children,
  }: {
    params?: EventParams<TRACK_EVENTS>
    children: React.ReactNode
  }) => {
    const parentParams = useContext(TrackingContext)

    return (
      <TrackingContext.Provider
        value={{ ...parentParams, ...params } as EventParams<TRACK_EVENTS>}
      >
        {children}
      </TrackingContext.Provider>
    )
  }

  const useTriggerTrackingEvent = () => {
    const params = useContext(TrackingContext)
    const { track } = useSegmentTracking()

    const triggerTrackingEvent: TriggerEvent<TRIGGER_EVENTS> = (
      eventType,
      event,
    ) => {
      eventHandlers[eventType](track, event, params ?? {})
    }

    return triggerTrackingEvent
  }

  const useTrackPage = (
    page: string,
    params?: EventParams<TRACK_EVENTS>,
    enabled: boolean = true,
  ) => {
    const tracked = useRef('')
    const contextParams = useContext(TrackingContext)
    const { trackPage } = useSegmentTracking()

    // useEffect to ensure we don't run this during SSR, as we may need to access window
    useEffect(() => {
      // tracked ref ensures we only do this once per page
      if (enabled && tracked.current !== page) {
        trackPage(page, { name: page, ...contextParams, ...params })
        tracked.current = page
      }
    }, [enabled, page, contextParams, params, trackPage])
  }

  return {
    TrackingProvider,
    useTriggerTrackingEvent,
    useTrackPage,
  }
}
