import React, { CSSProperties } from 'react'

export const VideoControl = ({
  children,
  className = '',
  style,
}: {
  children: React.ReactNode
  className?: string
  style?: CSSProperties
}) => (
  <div
    className={`d-flex flex-row align-items-center ${className}`}
    style={style}
  >
    {children}
  </div>
)
