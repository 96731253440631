import Hls from 'hls.js'
import { useBucket } from '@mc/experiments-react'
import { isIOS } from './isIOS'

const MPEG_TYPE = 'application/x-mpegURL'
const MOBILE_WEB_ENGINE_PREFERENCE = 'mobile_web_prefer_native_hls'

export type Engine = 'hls.js' | 'native-hls' | 'non-hls'

export const useDeterminePlaybackEngine = (
  video: Pick<HTMLVideoElement, 'canPlayType'> | null,
): Engine => {
  const enginePreference = useBucket<'native-hls' | 'hls.js' | null>(
    MOBILE_WEB_ENGINE_PREFERENCE,
  )

  const useNativeHLS = isIOS() && !!video?.canPlayType(MPEG_TYPE)
  const useHlsJS =
    (!useNativeHLS || enginePreference === 'hls.js') && Hls.isSupported()

  // eslint-disable-next-line no-nested-ternary
  return useHlsJS ? 'hls.js' : useNativeHLS ? 'native-hls' : 'non-hls'
}
