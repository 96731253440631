import React from 'react'
import { useTranslation } from '@mc/i18n'
import { Accordion } from '@mc/design-system'
import { Faq } from './LoggedOutLandingPage'
import { TrackingSectionContainer } from '../../components'
import { ROW_TRACKING } from './utils'
import { usePersonaEvents } from '../../events'

type FaqSectionProps = {
  faqs: Faq[]
  rowAnalytics?: { rowName: string; rowPositionIndex: number }
  instructorSlug?: string
}

export const FaqSection = ({
  faqs,
  rowAnalytics = ROW_TRACKING.faqs,
}: FaqSectionProps) => {
  const { t } = useTranslation('@mc/persona')
  const { trackPersonaSignClick } = usePersonaEvents()

  const handleExpand = (uuids: Array<string | number>) => {
    const [uuid] = uuids ?? []

    if (uuid === undefined) return

    trackPersonaSignClick({
      location: rowAnalytics.rowName,
      cta: faqs[uuid as number].question,
      action: 'expand',
    })
  }

  return (
    <TrackingSectionContainer analytics={rowAnalytics}>
      <div className='container mc-py-10 d-flex flex-column align-items-center justify-content-center'>
        <h2 className='mc-text-h1 mc-text--center mc-mx-auto mc-mb-9'>
          {t('landing.faq.title')}
        </h2>
        <Accordion
          onChange={handleExpand}
          style={{ width: '100%', maxWidth: '700px' }}
        >
          {faqs.map((faq, i) => (
            <Accordion.Item
              key={i}
              uuid={i}
              className='mc-lp--faqs--item mc-mb-2 mc-lp-text-color--light mc-text--left mc-corners--lg fancy-background'
              label={<h3 className='mc-text-h5'>{faq.question}</h3>}
            >
              <p
                style={{ whiteSpace: 'pre-wrap' }}
                dangerouslySetInnerHTML={{ __html: faq.answer }}
              />
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </TrackingSectionContainer>
  )
}
