import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { FieldInputProps } from 'react-final-form'

import { PROP_TYPE_CHILDREN } from '../constants'

export type CheckboxProps = Partial<FieldInputProps<string, HTMLInputElement>>

export default class Checkbox extends PureComponent<CheckboxProps> {
  static propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    label: PROP_TYPE_CHILDREN,
    name: PropTypes.string,
    ariaLabel: PropTypes.string,

    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
  }

  handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const { checked, disabled, onChange, onFocus, onBlur } = this.props

    // TODO: This click event doesn't fit here
    if (onFocus) onFocus(event as unknown as React.FocusEvent<HTMLInputElement>)
    if (onBlur) onBlur(event as unknown as React.FocusEvent<HTMLInputElement>)

    return !disabled && onChange?.(!checked)
  }

  render() {
    const { checked, className, disabled, label, ariaLabel, name } = this.props

    const classes = cn({
      [className ?? '']: className,
      'mc-input-checkbox': true,
      'mc-input-checkbox--checked': checked,
      'mc-input-checkbox--disabled': disabled,
    })
    return (
      <div className={classes} onClick={this.handleClick}>
        <input
          type='checkbox'
          name={name}
          id={name}
          className='mc-input-checkbox__realbox'
          checked={checked}
          disabled={disabled}
          aria-checked={checked}
          aria-labelledby={`${name?.replace(/ /g, '')}-label`}
          readOnly // since it's a controlled input that we manage with a click on the parent component.
          // Without readOnly, we get a warning when using the checkbox in a <Field/> with component={CheckboxField}
          // Alternatively, we could have onChange={() => {}}
        />
        <span className='mc-input-checkbox__fauxbox mc-mr-3 flex-shrink-0' />
        {/* ariaLabel is a Fallback if label is not a string (e.g. <p>My Label</p>) */}
        <label id={`${name?.replace(/ /g, '')}-label`} aria-label={ariaLabel}>
          {label}
        </label>
      </div>
    )
  }
}
