/* istanbul ignore file */
import * as React from 'react'
import type { SVGProps } from 'react'
import PropTypes from 'prop-types'

interface Props extends SVGProps<SVGSVGElement> {
  srText?: string
  size?: 'sm' | 'md' | 'lg' | 'scale-8' | 'scale-10' | number
  alt?: string
}
function IconSettingsDefault({
  srText,
  size = 'md',
  className = '',
  ...props
}: Props) {
  const newClassName = `mc-icon mc-icon--${size} ${className}`
  return (
    <>
      {!!srText && <span className='mc-sr-only'>{srText}</span>}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={25}
        fill='none'
        viewBox='0 0 24 24'
        aria-hidden='true'
        role='img'
        className={newClassName}
        {...props}
      >
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M12 2.5c.614 0 1.111.497 1.111 1.111v1.19a7.74 7.74 0 0 1 3.548 1.47l.84-.84A1.111 1.111 0 1 1 19.072 7l-.84.842A7.74 7.74 0 0 1 19.7 11.39h1.189a1.111 1.111 0 1 1 0 2.222h-1.19a7.74 7.74 0 0 1-1.47 3.545l.842.842a1.111 1.111 0 0 1-1.571 1.571l-.842-.841a7.74 7.74 0 0 1-3.547 1.47v1.189a1.111 1.111 0 1 1-2.222 0v-1.19a7.74 7.74 0 0 1-3.546-1.469l-.843.843a1.111 1.111 0 0 1-1.571-1.571l.843-.843a7.74 7.74 0 0 1-1.47-3.546h-1.19a1.111 1.111 0 0 1 0-2.222h1.19A7.74 7.74 0 0 1 5.77 7.842L4.929 7A1.111 1.111 0 1 1 6.5 5.43l.842.84a7.74 7.74 0 0 1 3.547-1.469v-1.19c0-.614.498-1.111 1.111-1.111m5.556 10.032a5.54 5.54 0 0 1-1.613 3.882l-.015.015-.014.015a5.556 5.556 0 1 1 1.642-3.912'
          clipRule='evenodd'
        />
      </svg>
    </>
  )
}
IconSettingsDefault.propTypes = {
  srText: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
} as PropTypes.ValidationMap<Props>
export default IconSettingsDefault
