import React, { useRef } from 'react'
import { defer } from 'lodash'

import { useTranslation } from '@mc/i18n'

import { useVideo } from '..'
import { ControlDropdown } from './ControlDropdown'
import { useVideoSetting } from '../userSettings'
import { isIOS } from '../isIOS'

const SPEEDS = [0.5, 0.75, 1, 1.25, 1.5, 2]

/**
 * Displays an interactive speed control
 *
 * Backed by localStorage
 */
export const VideoSpeed = () => {
  const { speed, setSpeed } = useVideo()
  const { t } = useTranslation('@mc/video')

  const [savedRate, setSavedRate] = useVideoSetting('video_rate')

  const initializedRef = useRef(false)
  if (
    typeof savedRate === 'number' &&
    savedRate !== speed &&
    !isIOS() &&
    !initializedRef.current
  ) {
    defer(setSpeed, savedRate)
    initializedRef.current = true
  }

  const handleClick = (newSpeed: number) => {
    if (typeof newSpeed === 'number') {
      setSpeed(newSpeed)
      setSavedRate(newSpeed)
    }
  }

  return (
    <ControlDropdown
      title={t('video.speed.title')}
      icon={t('video.speed.value', { value: speed })}
      selectedValue={speed}
      options={SPEEDS.map((s) => ({ label: s.toString(), value: s }))}
      onClick={handleClick}
    />
  )
}
