import React, { useState, useEffect } from 'react'

import { withI18nContext, useTranslation } from '@mc/i18n'
import { Button, IconVolumeOnDefault } from '@mc/design-system'

import { useVideo } from '.'
import i18nInstance from '../i18n/i18nInstance'

type VideoAutoplayProps = React.ComponentProps<typeof Button> & {
  muted?: boolean
}

/**
 * Autoplays the video when it has loaded.
 * If auto-muted by the browser, displays a "Tap to Unmute" button.
 * Props are forwarded to the underlying Button component.
 */
export const VideoAutoplay = withI18nContext(i18nInstance)(({
  muted: explicitMute,
  ...props
}: VideoAutoplayProps) => {
  const { muted, source, state, readyState, setMuted, play } = useVideo()
  const [show, setShow] = useState(false)
  const { t } = useTranslation('@mc/video')

  useEffect(() => {
    if (!source) return
    if (readyState !== 'ready' || state !== 'idle') return

    play({ autoPlay: true }).catch((ex) => {
      if (ex instanceof DOMException && ex.name === 'NotAllowedError') {
        // DOMException: play() failed because the user didn't interact with the document first.
        // see: https://developer.chrome.com/blog/autoplay/
        setMuted(true)
        setShow(true)
        return play({ autoPlay: true })
      }

      return Promise.resolve()
    })
  }, [source, readyState, state, play, setMuted])

  if (!show) return <></>
  if (!muted) return <></>

  if (explicitMute) {
    return null
  }

  const handleClick = () => {
    setMuted(false)
    setShow(false)
  }

  return (
    <Button
      kind='play'
      className='position-absolute'
      onClick={handleClick}
      {...props}
      style={{
        left: 0,
        top: 0,
        zIndex: 1000,
        margin: 'var(--mc-scale-4)',
      }}
    >
      <IconVolumeOnDefault className='mc-mr-2' />
      {t('video.mute.tap')}
    </Button>
  )
})
