import React from 'react'
import { useTranslation } from '@mc/i18n'

import IconWisdom from '../assets/icon-wisdom.svg'
import IconTalkItThrough from '../assets/icon-talk-it-through.svg'
import IconJustForYou from '../assets/icon-just-for-you.svg'
import IconPrivacy from '../assets/icon-privacy.svg'
import { TrackingSectionContainer } from '../../../components'
import { ROW_TRACKING } from '../utils'
import styles from './InfoSection.module.scss'

export const InfoSection = ({
  rowAnalytics = ROW_TRACKING.aiPoweredByPros,
}: {
  rowAnalytics?: {
    rowName: string
    rowPositionIndex: number
  }
}) => {
  const { t } = useTranslation('@mc/persona')

  const items = [
    {
      icon: <IconWisdom />,
      title: t('landing.info.1.title'),
      description: t('landing.info.1.description'),
    },
    {
      icon: <IconJustForYou />,
      title: t('landing.info.2.title'),
      description: t('landing.info.2.description'),
    },
    {
      icon: <IconTalkItThrough />,
      title: t('landing.info.3.title'),
      description: t('landing.info.3.description'),
    },
    {
      icon: <IconPrivacy />,
      title: t('landing.info.4.title'),
      description: t('landing.info.4.description'),
    },
  ]

  return (
    <TrackingSectionContainer analytics={rowAnalytics}>
      <div className='container d-flex flex-column align-items-center mc-corners--lg mc-px-8 mc-py-10'>
        <h2 className={`mc-text-h1 mc-text--center mc-mb-8 ${styles.header}`}>
          {t('landing.info.title')}
        </h2>
        <div className='d-flex flex-wrap justify-content-center mc-gap-9 mc-mt-9 col-12 col-lg-10 col-xl-8'>
          {items.map((item) => (
            <div key={item.title} className='d-flex justify-content-center'>
              <div
                className='mc-mb-4 mc-mr-4'
                style={{
                  color:
                    'radial-gradient(70.06% 70.75% at 49.68% 0%, rgba(251, 125, 247, 0.8) 0%, rgba(222, 156, 253, 0) 100%)',
                }}
              >
                {item.icon}
              </div>
              <div style={{ maxWidth: '273px' }}>
                <h3 className='mc-text-h5 mc-mb-2'>{item.title}</h3>
                <p className='mc-text-color--medium'>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </TrackingSectionContainer>
  )
}
