import { type Level } from 'hls.js'
import { compose, sortBy, uniqBy, reverse } from 'lodash/fp'
import { type QualityLevelOption } from '.'

// uniqBy keeps the first item in the array, so we reverse, uniqBy, then reverse again
// the sortBy is probably redundant to the default sort order, but we want to ensure it anyways
export const dedupeAndMapLevels = (
  levels: readonly Pick<Level, 'height' | 'bitrate'>[],
): QualityLevelOption[] =>
  compose(
    reverse,
    uniqBy('name'),
    reverse,
    sortBy('bitrate'),
  )(
    levels.map((l, i) => ({
      name: `${l.height}p`,
      value: i,
      height: l.height,
      bitrate: l.bitrate,
      hd: l.height >= 720,
    })),
  )
