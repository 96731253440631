import React from 'react'
import PropTypes from 'prop-types'
import { FieldRenderProps } from 'react-final-form'

import Checkbox from '../Checkbox'
import FormGroup, { FormGroupProps } from '../FormGroup'
import { parseMessage } from '../Forms/utils'

const INPUT_PROP_TYPE = PropTypes.shape({
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
})

const META_PROP_TYPE = PropTypes.shape({
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  touched: PropTypes.bool,
})

type CheckboxFieldProps = FieldRenderProps<boolean | string, HTMLElement>

const CheckboxField = ({
  input: { value, ...input },
  meta: { touched, error },
  ...props
}: CheckboxFieldProps) => {
  const parsedError = parseMessage(error)

  return (
    <FormGroup
      {...(input as unknown as FormGroupProps & {
        ref: React.LegacyRef<FormGroup>
      })}
      touched={touched}
      error={parsedError}
    >
      <Checkbox {...input} {...props} checked={!!value} />
    </FormGroup>
  )
}

CheckboxField.propTypes = {
  input: INPUT_PROP_TYPE,
  meta: META_PROP_TYPE,
} as PropTypes.InferProps<typeof Checkbox>

export default CheckboxField
