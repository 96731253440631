import React, { useRef, useState } from 'react'
import { format } from 'date-fns'

import { Slider } from '@mc/design-system'

import { useVideo } from '..'
import { Segment, VideoSegmentProps, VideoSegments } from './VideoSegments'
import { VideoScrubberPopup } from './VideoScrubberPopup'

const STEP = 10

type Props<TSegment extends Segment> = Partial<VideoSegmentProps<TSegment>> & {
  /**
   * Content to render as a popup over the mouse cursor position on the progress bar.
   * Receives the current time as a render prop.
   */
  popup?: (time: number) => React.ReactNode
}

/**
 * Displays a moving video scrubber (aka progress bar), along with segments (if provided)
 * and any provided children as a popup over the current mouse cursor position
 */
export const VideoScrubber = <TSegment extends Segment>({
  popup,
  segments,
  onSegmentEnter,
  onSegmentExit,
}: Props<TSegment>) => {
  const {
    buffer,
    duration,
    time,

    seekingTo,
    seekTo,
  } = useVideo()
  const [hovering, setHovering] = useState(false)
  const [mouseX, setMouseX] = useState(-1000)
  const container = useRef<HTMLDivElement>(null)

  const containerWidth = container.current?.offsetWidth ?? 0
  const hoverTime =
    containerWidth > 0 ? Math.floor((mouseX / containerWidth) * duration) : 0

  return (
    <div
      className='mc-video__scrubber'
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onMouseMove={(e) =>
        setMouseX(
          e.clientX - (e.currentTarget?.getBoundingClientRect().left ?? 0),
        )
      }
      ref={container}
    >
      <Slider
        value={time / duration}
        buffer={buffer / duration}
        step={STEP / duration}
        onChange={(value: number) => seekingTo(value * duration)}
        onChangeEnd={(value: number) => seekTo(value * duration)}
        transitionDuration={0}
      >
        {segments && (
          <VideoSegments
            segments={segments}
            onSegmentEnter={onSegmentEnter}
            onSegmentExit={onSegmentExit}
          />
        )}
        <VideoScrubberPopup show={hovering} x={mouseX}>
          {popup?.(hoverTime) ?? <div>{format(hoverTime * 1000, 'm:ss')}</div>}
        </VideoScrubberPopup>
      </Slider>
    </div>
  )
}
