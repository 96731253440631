/* istanbul ignore file */
import * as React from 'react'
import type { SVGProps } from 'react'
import PropTypes from 'prop-types'

interface Props extends SVGProps<SVGSVGElement> {
  srText?: string
  size?: 'sm' | 'md' | 'lg' | 'scale-8' | 'scale-10' | number
  alt?: string
}
function IconCheckDefault({
  srText,
  size = 'md',
  className = '',
  ...props
}: Props) {
  const newClassName = `mc-icon mc-icon--${size} ${className}`
  return (
    <>
      {!!srText && <span className='mc-sr-only'>{srText}</span>}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={25}
        fill='none'
        viewBox='0 0 24 24'
        aria-hidden='true'
        role='img'
        className={newClassName}
        {...props}
      >
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M21.646 5.31c.45.434.474 1.164.056 1.63L10.324 19.632c-.21.235-.504.368-.813.368s-.603-.133-.813-.368l-6.4-7.14a1.185 1.185 0 0 1 .056-1.63 1.08 1.08 0 0 1 1.57.059l5.587 6.232L20.076 5.368a1.08 1.08 0 0 1 1.57-.059'
          clipRule='evenodd'
        />
      </svg>
    </>
  )
}
IconCheckDefault.propTypes = {
  srText: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
} as PropTypes.ValidationMap<Props>
export default IconCheckDefault
