import React from 'react'
import { Field, FieldInputProps } from 'react-final-form'

import { useTranslation, withI18nContext } from '@mc/i18n'
import { InputField } from '@mc/design-system'

import { i18nInstance } from '../i18n/i18nInstance'
import { getCartEmailCookie } from '../helpers'
import { EMAIL_CAPTURE_I18N } from '../constants'

interface EmailCaptureInputProps extends Partial<FieldInputProps<string>> {
  label?: string
  placeholder?: string
  initialValue?: string
  className?: string
  validate?: (email: string) => void
  showLabel?: boolean
}

const EmailCaptureInputComponent = ({
  label,
  placeholder,
  initialValue,
  className = 'mc-my-4',
  showLabel = true,
  ...props
}: EmailCaptureInputProps) => {
  const { t } = useTranslation(EMAIL_CAPTURE_I18N)

  const persistedEmail = getCartEmailCookie()

  return (
    <Field
      className={className}
      render={({ input, meta, ...restProps }) => (
        <InputField {...restProps} input={input} meta={meta} />
      )}
      name='email'
      id='email'
      type='email'
      label={showLabel ? label || t('email.label') : null}
      placeholder={placeholder ?? t('email.placeholder')}
      initialValue={initialValue || persistedEmail}
      autoComplete='email'
      required
      {...props}
    />
  )
}

export const EmailCaptureInput = withI18nContext(i18nInstance)(
  EmailCaptureInputComponent,
)
