import React from 'react'
import PropTypes from 'prop-types'
import { Accordion as A11yAccordion } from 'react-accessible-accordion'
import Item from './Item'
import Section from './Section'

const Accordion = ({
  children,
  ...props
}: React.ComponentProps<typeof A11yAccordion>) => (
  <A11yAccordion allowZeroExpanded {...props}>
    {children}
  </A11yAccordion>
)

Accordion.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
}

Accordion.Item = Item
Accordion.Section = Section

export default Accordion
