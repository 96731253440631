import { useEffect } from 'react'

import { useBucket } from '@mc/experiments-react'
import { trackExperimentViewed } from '@mc/track-event'

// Growthbook experiment: https://app.growthbook.io/features/persona_video
const PERSONA_VIDEO_EXPERIMENT_FLAG = 'persona_video'

export const usePersonaVideoEnabled = () => {
  const bucket = useBucket<'control' | 'variant_1'>(
    PERSONA_VIDEO_EXPERIMENT_FLAG,
  )

  useEffect(() => {
    if (bucket)
      trackExperimentViewed(
        PERSONA_VIDEO_EXPERIMENT_FLAG,
        {},
        { trackUserLeap: true },
      )
  }, [bucket])

  return bucket === 'variant_1'
}
