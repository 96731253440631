import React from 'react'
import PropTypes from 'prop-types'

type AsHeading = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p'

export interface SectionProps extends React.HTMLAttributes<HTMLDivElement> {
  as?: AsHeading
  label: string
}

const Section = ({ as = 'h3', className, label, children }: SectionProps) => {
  const Heading = as
  return (
    <div className={`mc-mb-6 ${className}`}>
      <Heading className='mc-opacity--hinted mc-text--bold mc-mb-4'>
        {label}
      </Heading>
      {children}
    </div>
  )
}

Section.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p']),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
}

export default Section
