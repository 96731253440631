import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { PROP_TYPE_CHILDREN } from '../constants'

export const BadgeKinds = {
  DEFAULT: 'default',
  DIM: 'dim',
  TRANSPARENT: 'transparent',
  PRIMARY: 'primary',
  UTILITY: 'utility',
  ANNOUNCE: 'announce',
  URGENCY: 'urgency',
} as const

export type BadgeKindValue = (typeof BadgeKinds)[keyof typeof BadgeKinds]

interface BadgeProps
  extends React.PropsWithChildren<React.HTMLProps<HTMLElement>> {
  kind?: BadgeKindValue
}

export default class Badge extends PureComponent<BadgeProps> {
  static propTypes = {
    children: PROP_TYPE_CHILDREN,
    className: PropTypes.string,
    kind: PropTypes.oneOf(Object.values(BadgeKinds)),
  }

  static defaultProps = {
    kind: 'default',
  }

  render() {
    const { children, className, kind, ...props } = this.props

    const classNames = cn({
      'mc-badge mc-text-x-small': true,
      [`mc-badge--${kind}`]: kind,
      [className ?? '']: className,
    })

    return (
      <span className={classNames} {...props}>
        {children}
      </span>
    )
  }
}
