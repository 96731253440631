/* istanbul ignore file */
import * as React from 'react'
import type { SVGProps } from 'react'
import PropTypes from 'prop-types'

interface Props extends SVGProps<SVGSVGElement> {
  srText?: string
  size?: 'sm' | 'md' | 'lg' | 'scale-8' | 'scale-10' | number
  alt?: string
}
function IconVolumeOnDefault({
  srText,
  size = 'md',
  className = '',
  ...props
}: Props) {
  const newClassName = `mc-icon mc-icon--${size} ${className}`
  return (
    <>
      {!!srText && <span className='mc-sr-only'>{srText}</span>}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={25}
        fill='none'
        viewBox='0 0 24 24'
        aria-hidden='true'
        role='img'
        className={newClassName}
        {...props}
      >
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M11.418 4.581c.355.145.582.46.582.808V19.61c0 .347-.227.663-.582.808a1.1 1.1 0 0 1-1.069-.133L6.63 17.452H3c-.552 0-1-.398-1-.889V8.438c0-.491.448-.89 1-.89h3.63l3.72-2.833a1.1 1.1 0 0 1 1.068-.133M10 7.321l-2.35 1.79a1.08 1.08 0 0 1-.65.214H4v6.35h3c.239 0 .47.075.65.214l2.35 1.79zM15.598 9.243c.506-.197 1.096.007 1.318.457.404.819.584 1.834.584 2.802 0 .967-.18 1.982-.584 2.801-.222.45-.812.654-1.318.457-.505-.197-.735-.722-.513-1.171.262-.531.415-1.286.415-2.088 0-.801-.153-1.556-.415-2.087-.222-.45.008-.974.513-1.17M19.527 7.72c.487-.233 1.093-.07 1.354.362.764 1.266 1.12 2.868 1.12 4.42s-.356 3.154-1.12 4.42c-.26.433-.867.596-1.354.364-.486-.233-.67-.771-.408-1.204.57-.943.881-2.237.881-3.58s-.312-2.636-.881-3.58c-.261-.432-.078-.97.408-1.203'
          clipRule='evenodd'
        />
      </svg>
    </>
  )
}
IconVolumeOnDefault.propTypes = {
  srText: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
} as PropTypes.ValidationMap<Props>
export default IconVolumeOnDefault
