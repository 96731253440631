import React, { useRef } from 'react'
import { defer } from 'lodash'

import { useTranslation } from '@mc/i18n'

import { IconCcDefault, IconCcActive } from '@mc/design-system'

import { useVideoSetting } from '../userSettings'

import { useVideo } from '..'
import { ControlDropdown } from './ControlDropdown'

/**
 * Displays closed captions (subtitles) according to the user's language setting,
 * if available
 *
 * Backed by localStorage
 */
export const VideoClosedCaptions = () => {
  const { tracks, track, setTrack } = useVideo()
  const { t } = useTranslation('@mc/video')

  const [savedTrackCaption, setSavedTrackCaption] =
    useVideoSetting('video_captions')

  const savedTrackIndex = tracks.findIndex(
    (trk) =>
      trk.srcLang === savedTrackCaption || trk.label === savedTrackCaption,
  )

  const initializedRef = useRef(false)
  if (
    savedTrackIndex > -1 &&
    savedTrackIndex !== track &&
    !initializedRef.current
  ) {
    defer(setTrack, savedTrackIndex)
    initializedRef.current = true
  }

  const handleClick = (index: number) => {
    const newCaption = index === -1 ? '' : tracks[index]?.srcLang
    setSavedTrackCaption(newCaption)
    setTrack(index)
  }

  const trackOptions = tracks
    .map((trk, i) => ({
      label: trk.label || trk.srcLang,
      value: i,
      kind: trk.kind,
    }))
    .filter((trk) => ['subtitles', 'captions'].includes(trk.kind))

  if (!trackOptions || trackOptions.length === 0) return <></>

  return (
    <ControlDropdown
      title={t('video.subtitles.title')}
      icon={track === -1 ? <IconCcDefault /> : <IconCcActive />}
      selectedValue={track}
      onClick={handleClick}
      options={[{ label: 'Off', value: -1 }, ...trackOptions]}
    />
  )
}
